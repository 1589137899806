/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// import { v4 as uuidv4 } from 'uuid';
import database from '../firebase/firebase';

/* eslint-disable linebreak-style */
// SET_START_DATE
export const setStartDate = (startDate: any) => ({
  type: 'SET_START_DATE',
  startDate
});
export const setEndDate = (startDate: any) => ({
  type: 'SET_END_DATE',
  startDate
});

export const editService = (id: any, updates: any) => ({
  type: 'EDIT_SERVICE',
  id,
  updates
});

export const  startRemoveDatabase = (servYear: any) => {
  return (dispatch: any, getState: any ) => {
    const uid = getState().auth.uid;
    return database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram`).remove()
      .catch((e: any) => {
        console.log('Did not remove data services.ts')
      })
  }
}

export const startEditServiceProgram = (id: any, updates: any,servYear: any) => {
  const updates_save = updates;
  return ( dispatch: any, getState: any ) => {
    const uid = getState().auth.uid;
    return database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram/${id}`).update(updates_save).then(() => {
      dispatch(editService(id, updates_save));
    })
  }
}
export const loadOnce= (serviceList: any) => ({
  type: 'ADD_SERVICE_PROGRAM_ONCE',
  serviceList
});

