
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import {Box, IconButton, Table ,TableBody, TableHead, TableCell, TableRow, Typography} from '@mui/material';
import { colors } from '../../styles/themeStylesSx';
import { lineStyles } from '../../styles/themeStylesSx';
import SidePanelMedium from '../sidepanel/SidePanelMedium';
import DeleteGroupMember from '../drives/groups/DeleteGroupMember';
import {  DeleteRounded } from '@mui/icons-material';
import { RootState } from '../../store/ConfigureStore';
type propsTypes = {
  close: () =>  void;
  groupEmail: any
  groupName: any,
  groupId: any,
  sheetGroup: any,
  googleGroup: any,
  members: any;
}
type GroupStateType = {
  open?: boolean;
  group?: any;
  listOwners?: boolean;
  ownerWaiting?: boolean;
  doRefresh?: boolean;
  groupId?: string;
  deleteMember?: boolean;
  groupEmail?: any;
  groupName?: any;
  ownerName?: any;
  token?: any;
  compareServiceProgram?: any;
  listAll?: any;
  email?: any;

};

export default function HandleDifferences(props: propsTypes) {
  const users: any = useSelector((state: RootState) => state.users);
  const close = props.close
  const [checked, setChecked] = React.useState<readonly any[]>([]);
  const [left, setLeft] = React.useState<readonly any[]>([]);
  const [right, setRight] = React.useState<readonly any[]>([]);
  const auth: any = useSelector((state: RootState)  => state.auth);
  const groups: any = useSelector((state: RootState) => state.groups);
  const token = `bearer ${auth.idToken}`;
  const [state, setState] = useState<GroupStateType>({email: '', groupId: '', ownerName: '', groupName: '', token: token, deleteMember: false });
  const toLeftW = props.members.map((gr: any) => {
    const userName = users.find((us: any) => gr.email === us.primaryEmail);
    const nimi = userName ? userName.name.fullName : '';
    const givenName = userName ? userName.name.givenName : '';
    const familyName = userName ? userName.name.familyName : '';
    return [gr.email,' / ', nimi, givenName, familyName]
  });
  const toLeft = toLeftW.map((li: any) => {
    return (li[0] + ' / ' + li[2])
  });
  const toRight1 = props.sheetGroup.filter((line: any) =>line.length < 3 || (line.length > 2 && !line[2].includes('helsinginrauhanyhdistys')));
  const work = toRight1.sort((a: any, b: any) => {
    var x: any = a[0];
    var y: any = b[0];
    if (x < y) {return -1}
    if (x>y) {return 1}
    else return 0
  })
  const toRight = work.map((li: any) => {
    const sp: any = li[3] ? li[3] : 'Ei sähköpostiosoitetta';
    return [li[0] , ' / ', sp]; 
  });
  const actionNeeded1: any = toLeftW.filter((lef: any) => {
    const ind1 = toRight.findIndex  ((ri: any) => 
      ri[2] === lef[0] 
    ) ;
    const ind2 = toRight.findIndex((ri: any) =>
      lef[2] === ri[0]
    )
    const wo =lef[0].split('@')
    const wo1 = wo[0].replace(/\./g, " ");

    const ind3 = toRight.findIndex((ri: any) =>
      wo1.toLowerCase() === ri[0].toLowerCase()
    )
    let ind4 = -1;
    const workFamilyName = lef[4].toLowerCase(); //familyname
    const workGivenName = lef[3].toLowerCase(); //givenname
    ind4 = toRight.findIndex((ri: any) =>{
      const ri1 = ri[0].split(' ');
      if(ri1.length >1) {
  
        return (ri1[1].toLowerCase() === workFamilyName || ri1[0].toLowerCase() === workFamilyName)  &&
           (workGivenName === ri1[0].toLowerCase() || workGivenName === ri1[1].toLowerCase());
      };    
    });
    if ( ind1 === -1 && ind2 === -1 && ind3 ===  -1 && ind4 === -1 ) {
      return lef;
    }
  });
  const actionNeeded = actionNeeded1.map((act: any) => {
    let xxx : any = '';
    const workUser = users.find((user: any) => user.primaryEmail === act[0] )
    if (workUser) {
     xxx = [workUser.lastLoginDate];
    } 
    else {
      xxx = ''; 
    }
    const work: any[] = [
      act[0], act[1], act[2], act[3], act[4], xxx
    ]
   return work
  })
  
  useEffect( () => {  
    setRight(toRight);
    setLeft(toLeft);
  }, [props.sheetGroup, props.members]);

  const getGroups = () => {
    const foundGroup = groups.find((gr: any) => {
      return gr.id === props.groupId
    })
    return foundGroup;
  }
  const getMember = (email: string) => {
    const foundMember = props.members.find((mem: any ) => 
    mem.email === email
  )
return foundMember;
};

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const paluu = () => {
    close();
  }
  const customList = (title: string, items: readonly string[], right: any) => (
    <Box>
      <Paper sx={{ width: 400, height: 500, overflow: 'auto', p: 2 }}>
        {/* Otsikko */}
        <Typography variant="h6" sx={{ textAlign: 'center', mb: 1 }}>
          {title}
        </Typography>
  
        <List dense component="div" role="list">
          {items.map((value: any, ii: any) => {
            const labelId = `transfer-list-item-${value}-label`;
            return (
              <ListItem key={ii} role="listitem" tabIndex={-1} onClick={() => handleToggle(value)}>

                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value}`} />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Box>
  );
  
  return (  
    <Box>    
       {right.length === 0 ? 
      <Box>
         Seurakalenterissa ei vastaavaa ryhmää,  tarkista ryhmä kuitenkin.               
      </Box>
      :
      <Box>
        <SidePanelMedium
        open = { state.deleteMember === true }
        close={ () => setState({ ...state, deleteMember: false })}
        >
          <Box>
          { state.deleteMember &&
            <DeleteGroupMember member = {getMember(state.groupEmail) } group = {getGroups() } members = {props.members} openDelete = {true} close ={ () => setState({ open: false })} />
            }
            </Box>
        </SidePanelMedium>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{customList('Googleryhmän henkilöt',left, 'left')}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
            </Grid>
          </Grid>
          <Grid item>{customList('Seurakalenterin henkilöt',right, 'right')}</Grid>
        </Grid>
        <Box>
          <Box sx = {{p:2}}>
            <Typography sx={ lineStyles }>{`Seuraavat tilit puuttuvat seurakalenterista, poista vanhentuneet tiedot Googleryhmästä` }</Typography>
          </Box>
      
          <Box>
          <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
            <TableHead>
              <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                <TableCell>{}</TableCell>     
                <TableCell>{}</TableCell>
                <TableCell>{'Tili'} </TableCell>
                <TableCell>{'Henkilö'} </TableCell>
                <TableCell>{'Viim. kirj'} </TableCell>
        
              </TableRow>
            </TableHead>
            <TableBody>        
              {actionNeeded.map((line: any, i: any) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return  (
                  <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {`${i}_row7`}>
                    <TableCell sx={{
                      backgroundColor,
                      borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                      <IconButton onClick={() => setState({ deleteMember: true,  groupEmail: line[0], groupId: props.groupId, groupName: props.groupName, token: token })}><DeleteRounded /></IconButton>
                                        
                  </TableCell>
                  <TableCell></TableCell>
                    <TableCell>{line[0]}</TableCell>
                    <TableCell>{line[2]}</TableCell>
                    <TableCell>{line[5]}</TableCell>          
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
          <Button color="primary" variant="contained" sx={{ ml: 1 }}
          onClick= {paluu}>
          {('Paluu')}
        </Button>       
        </Box>    
      </Box>      
}
    </Box>
  );
}
