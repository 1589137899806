import React,{useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, haveToReadDrive } from '../../actions/filters';
import submitUsersToState from './submitUsersToState';
import submitDrivesToState from './submitDrivesToState';
import { emptyDrives } from '../../actions/drives';
import submitGroupsToState from './groups/submitGroupsToState';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import {RootState} from '../../store/ConfigureStore';
import {subHeaderStyles} from '../../styles/themeStylesSx';
import { useNavigate } from 'react-router-dom';

export const DriveStart = () => {
  const dispatch = useDispatch()
  const authorit: any = useSelector((state: RootState) => state.auth);
  const drivesx = useSelector((state: RootState) => state.drives);
  const filters = useSelector((state: RootState) => state.filters);
  const groups: any = useSelector((state: RootState)=> state.groups);
  const users = useSelector((state: RootState)=> state.users);
  const inputDataBase = useSelector((state: RootState) => state.filters.fromDataBase);
    const navigate = useNavigate();

  const  token: any = `bearer ${authorit.idToken}`

  let componentMounted = true;
  
  useEffect( () => {
    if (!authorit.idToken) {
      navigate('/');
      return  
    }
    if (filters.drive) {      
      const listDrives = async () => {     
        await submitUsersToState(token, dispatch);
        dispatch(emptyDrives())
        await submitDrivesToState(token,dispatch, authorit.uid, inputDataBase, authorit, users );
        await submitGroupsToState(token,authorit.uid, dispatch, 'no', filters, authorit);
        dispatch(setLoading(false));
        if (componentMounted) {
       
        }
        return () => { // This code runs when component is unmounted
          // eslint-disable-next-line react-hooks/exhaustive-deps
         console.log('unmounteeed')
          componentMounted = false; // (4) set it to false if we leave the page
        }
      }
       dispatch(setLoading(true));
      listDrives ();      
    }      
    else {
      dispatch(setLoading(false));
    }
  }, [filters.fromDataBase, filters.haveToReadDrive, navigate, authorit.idToken]);
  if (!authorit.idToken) return <div> Kirjaudu järjestelmään</div>
  if (filters.loading) return <div> Lataa Ryhmien ja kansioiden tietoja<CircularProgress/></div>
  if (!filters.drive) return <div> Ei tarvittavia oikeuksia</div>

  return (
    <Box>
    
      <Box>
        <Button variant="contained" color="primary"
          onClick = {(e) => { dispatch(haveToReadDrive(true))}}>Lataa </Button>
      </Box>
      <Box>
        <Typography sx={ subHeaderStyles }>{'Ladattu ' + drivesx.length + ' Drive-kansiota' }</Typography>
        <Typography sx={ subHeaderStyles }>{'Ladattu ' + users.length + ' Drive-käyttäjää' }</Typography>
        <Typography sx={ subHeaderStyles }>{'Ladattu ' + groups.length + ' Drive-ryhmää' }</Typography>
      </Box>
    </Box>
  )
}
  export default DriveStart;