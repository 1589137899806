/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect }  from 'react';
import { useSelector  } from 'react-redux';
import { CircularProgress, Box, IconButton, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ListGroupMembersSimple from './ListGroupMembersSimple';
import {RootState} from '../../../store/ConfigureStore';
import {subHeaderStyles} from '../../../styles/themeStylesSx';
import { colors } from '../../../styles/themeStylesSx';
import SidePanelMedium from '../../sidepanel/SidePanelMedium';
import { VisibilityRounded } from '@mui/icons-material';

const DriveGroups = () => {
  const navigate = useNavigate();
  const filters = useSelector((state: RootState) => state.filters);
  const groups: any = useSelector((state: RootState)=> state.groups);
  const [groupState, setGroupState] = useState<any>([]);

  type GroupStateType = {
    open?: boolean;
    group?: any;
    ownerWaiting?: boolean;
  };

  const [state, setState] = useState<GroupStateType>({ open: false, group: '' });

  useEffect( () => {
    if (groups.length === 0 ) {  
      navigate("/drive");
      return;
    }
    setGroupState (groups.sort((a: any, b: any) => a.name.localeCompare(b.name)));
  },[])

  if (filters.loading) return <div><CircularProgress /> <br />...latailee filtterssii  vieläkinnnn</div>

  if (groups.length !== 0) {
    if (!filters.write) return (<div> <br />. Ei vaadittuja oikeuksia drive managing</div>)
  }

  return (
    <Box>
      {groupState.length > 0 &&  (typeof(groups[0].members) !== undefined ) &&
        <Box>
          <SidePanelMedium
            open={ state.open === true }
            close={ () => setState({ open: false })}
          >  
            <Box>
              {state.open &&           
                <ListGroupMembersSimple group= {state.group }  close ={ () => setState({ open: false })} />
              }
            </Box>
          </SidePanelMedium>
          <Box>
            <Typography sx={ subHeaderStyles }>{`Helsingin Rauhanyhdistyksen  Google-Ryhmät ${groups.length} kpl` }</Typography>
            <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
              <TableHead>
                <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                  <TableCell>{}</TableCell>
                  <TableCell>{'Group-email'} </TableCell>
                  <TableCell>{'Grop-name'} </TableCell>
                  {/* <TableCell>{'Grop-owner'} </TableCell> */}
                  <TableCell>{'Grop-description '} </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {groupState.map((group: any, i: any) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return  (
                  <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {`${i}_row7`}>
                    <TableCell sx={{
                      backgroundColor,
                      borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                      <IconButton  onClick={() => setState({ open: true, group: group  })}><VisibilityRounded /></IconButton>    
                    </TableCell>
                    <TableCell>{group.email}</TableCell>
                    <TableCell>{group.name}</TableCell>
                    <TableCell>{group.description}</TableCell>
                  </TableRow>
                )
              })
              }
            </TableBody>
          </Table>
        </Box>
      </Box>
      }
     </Box>
  )
}
export default DriveGroups;