import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import SidePanel from '../sidepanel/SidePanel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { startLogin, startLogout } from '../../actions/auth';
import { editFilterData } from '../../actions/filters'
//import useLocales from '../../hooks/useLocales';
import { Button, FormControl, InputLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { colors } from '../../styles/themeStylesSx';
//import AddEditDocument from '../documents/AddEditDocument';
import { drawerWidth } from './SideNavigation';
import {RootState} from '../../store/ConfigureStore';

const modalStyle = {
  ml: 2,
  mr: 2,
  mb: 2,
};

type AppBarType = {
  open: boolean;
  type?: string;
}

export default function MenuAppBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authorit: any = useSelector((state: RootState) => state.auth)
  //const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //const { t } = useLocales();
  const [state, setState] = React.useState<AppBarType>({ open: false, type: '-1' });

  // const handleChange = (e: SelectChangeEvent)  => {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickLogout = () => {
    dispatch(startLogout());
    dispatch(editFilterData({ logOff:true }));
    handleClose();
    //navigate('/kirjaudu');
    return (<div> Krjauduttu ulos </div>)
   
  };
  const clickLogin = () => {

    if ( !authorit.uid)
      dispatch(startLogin());
  };

  const handleProfile = () => {
    handleClose();
   navigate('/profile/');
    // setState({ ...state, open: !state.open, openProfile: !state.openProfile })
  };

  const loadSqlTables =() => {
    handleClose();
    navigate('/loadSqlTables/')
  }

  return (
    <AppBar
        sx={{
          width: {
            lg: `calc(100% - ${drawerWidth}px)`
          },
          marginLeft: {
            lg: drawerWidth,
          },
          paddingLeft: {
            xs: '50px',
          },
        }}
        position="fixed">
      <Toolbar>
        <Box sx={{ width: '100%', display: 'flex', color: 'primary.contrastText', alignItems: 'center' }}>
          <SidePanel
            open={state.open}
            close={() => setState({ ...state, open: false }) }
            aria-labelledby="add-modal-title"
            aria-describedby="add-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography sx={{ mb: 2 }} id="add-modal-title" variant="h6" component="h2">
                {'Add'}
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="add-type-label">{'Type'}</InputLabel>
              </FormControl>
            </Box>
          </SidePanel>
          <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <Box>
              <Button
                endIcon={ <AccountCircle/> }
                variant="outlined"
                color="inherit"
                sx={{ mr: 2, borderColor: 'gray', textTransform: 'none', fontSize: { xs: '12px', md: '14px' } }}
                size="large" onClick={ clickLogin }>
                {'Login'}
              </Button>

            </Box>
             <Box sx={{ mr: 1}}>
              {authorit.speakerName}
            </Box>
            <IconButton
              sx={{ color: colors.darkerGreen }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfile}>{'My profile'}</MenuItem>
            <MenuItem onClick={clickLogout}>{'Logout'}</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}