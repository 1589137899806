/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import {startAddDrivesOnce } from  '../../actions/drives'
import { retrieveDrives } from '../../services/sqlLiteServices';
const SubmitDrivesToState = async (token: any,dispatch: any,uid: any, inputDataBase: any, auth: any, users: any) => {

  let drivelist: any = []
  let driveArray: any = []
  try {
    drivelist = await retrieveDrives(token);
    await drivelist.sort(( a: any, b: any ) => {
      var x= a.name
      var y = b.name
      if(x < y) {return -1}
      else
      if (x > y) {return 1}
      else return 0
    });
    await dispatch(startAddDrivesOnce(
      drivelist
    ));
    
  }
  catch(error) {
    console.error('Error fetching submitdrivestostate', error)
  }
return driveArray
}
export default SubmitDrivesToState