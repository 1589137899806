
import './App.css';
import AppMenuBar from './components/layout/AppMenuBar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SideNavigation from './components/layout/SideNavigation';
import { Box, Snackbar } from '@mui/material';
import {   BrowserRouter, Route, Routes, Router } from 'react-router-dom';
import Switch from '@mui/material';
import InfoPage from './components/admin/InfoPage';
import React, { useState, useEffect, lazy, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setTextFilter , editFilterData} from './actions/filters'
import { firebase } from './firebase/firebase'
import 'firebase/auth'
import { login, logout, updateIdToken } from './actions/auth';
import { colors } from './styles/themeStylesSx';
import {credentialsOnly} from './services/authdecodedtoken';
import { decodedToken } from './services/authdecodedtoken';

// import ServiceAnalyze from './components/service/analyzes/ServiceAnalyze';
import  CheckCals from './components/calendars/CheckCals';
// import  UpdateCals from './components/calendars/UpdateCals';
import CalendarContainer from './components/containers/CalendarContainer';
//import AuthenticationContainer from './components/containers/AuthenticationContainer';
//import InfoContainer from './components/containers/InfoContainer';
//import AddSpeakerToLineContainer from './components/containers/AddSpeakerToLineContainer';
//import SetSpeakerToLine from './components/loadSpeakers/SetSpeakerToLine';
//import ServiceReports from './components/reports/ServiceReports';


//import GmailContainer from './components/containers/GmailContainer';
// import SandBox from './components/containers/SandBox';
// import SandBox1 from './components/containers/SandBox1';
//import GmailStart from './components/gmail/GmailStart';
//import UserListing from './components/users/UserListing';
import UserNoRoleComponent from './components/users/UserNoRoleComponent';
//import UserComponent from './components/users/UserComponent';
//import UserContainer from './components/containers/UserContainer';
import AclListCalendar from './components/calendars/AclListCalendar';
import CalendarStart from './components/calendars/CalendarStart';
import ProfileContainer from './components/containers/ProfileContainer';
import LoadSqlTables from './components/profile/LoadSqlTables';
import ProfilePage from './components/profile/ProfilePage';
import AdminContainer from './components/containers/AdminContainer';
//import GetFirebaseGroup from './components/admin/GetFirebaseGroup';
//import ChangeYear from './components/admin/ChangeYear';
import GetFirebaseGroup from './components/admin/GetFirebaseGroup';
import CheckServiceProgram from './components/admin/CheckServiceProgram';
import UserComponent from './components/users/UserComponent';
import UserListing from './components/users/UserListing';
import DriveUsers from './components/drives/DriveUsers';
import DriveStart from './components/drives/DriveStart';
import DriveListing from './components/drives/DriveListing';
import LoginContainer from './components/containers/LoginContainer';
import { subHeaderStyles } from './styles/themeStylesSx';
import {Typography, CircularProgress} from '@mui/material';
import {RootState} from './store/ConfigureStore';
import { updateIdTokenOnly } from './actions/auth';
import DriveGroups from './components/drives/groups/DriveGroups';
import SpeakersContainer from './components/containers/SpeakersContainer';
import ListSpeakersPage from './components/speakers/ListSpeakersPage';
import ServicesContainer from './components/containers/ServicesContainer';
//import ReportsContainer from './components/containers/ReportsContainer';
import  ListServiceProgramPage from './components/service/ListServiceProgramPage';
//import AddServiceLine from './components/service/AddServiceLine';
//const DriveListing = lazy(() => import ( './components/drives/DriveListing')) ;
//const DriveStart = lazy(() => import ('./components/drives/DriveStart'))  ;
//const DriveGroups = lazy(() => import ('./components/drives/groups/DriveGroups' )) ;
//const DriveUsers = lazy (() => import('./components/drives/DriveUsers')) ;
const DriveContainer = lazy(() => import('./components/containers/DriveContainer')); 
//const UserListing = lazy(() => import('./components/users/UserListing')); 
//const UserNoRoleComponent = lazy(() => import('./components/users/UserNoRoleComponent')); 
const UserContainer = lazy(() => import('./components/containers/UserContainer')); 
//const AclListCalendar = lazy(() => import('./components/calendars/AclListCalendar')); 
//const CalendarStart = lazy(() => import('./components/calendars/CalendarStart')); 
//const ProfileContainer = lazy(() => import('./components/containers/ProfileContainer')); 
//const LoadSqlTables = lazy(() => import('./components/profile/LoadSqlTables')); 
//const ProfilePage = lazy(() => import('./components/profile/ProfilePage')); 
//const AdminContainer = lazy(() => import('./components/containers/AdminContainer')); 
//const GetFirebaseGroup = lazy(() => import('./components/admin/GetFirebaseGroup')); 
//const UserComponent = lazy(() => import('./components/users/UserComponent')); 
//const CheckServiceProgram = lazy(() => import('./components/admin/CheckServiceProgram')); 
//const LoginContainer = lazy(() => import('./components/containers/LoginContainer')); 
const ServiceReports = lazy(() => import('./components/reports/ServiceReports')); 
const SetSpeakerToLine = lazy(() => import('./components/loadSpeakers/SetSpeakerToLine')); 
const AddSpeakerToLineContainer = lazy(() => import('./components/containers/AddSpeakerToLineContainer')); 
const InfoContainer = lazy(() => import('./components/containers/InfoContainer')); 
const AuthenticationContainer = lazy(() => import('./components/containers/AuthenticationContainer')); 
//const CalendarContainer = lazy(() => import('./components/containers/CalendarContainer'));
//const CheckCals = lazy(() => import('./components/calendars/CheckCals'));
//const SpeakersContainer = lazy(() => import('./components/containers/SpeakersContainer'));
//const ListSpeakersPage = lazy(() => import('./components/speakers/ListSpeakersPage'));
//const ServicesContainer = lazy(() => import('./components/containers/ServicesContainer'));
const ReportsContainer = lazy(() => import('./components/containers/ReportsContainer'));
//const ListServiceProgramPage = lazy(() => import('./components/service/ListServiceProgramPage'));
const AddServiceLine = lazy(() => import('./components/service/AddServiceLine'));
const color1 = '#96CBA0';
const color2 = '#00687D';

const mdTheme = createTheme({
  shape: {
    borderRadius: 20,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.backgroundGreen,
          boxShadow: 'none',
          borderBottom: 'thin solid gray',
        }
      }
    },
  },
  palette: {
    primary: {
      main: color1,
    },
    secondary: {
      main: color2,
    },
    warning: {
      main: '#EF5F5F',
    }
  },
  typography: {
    fontFamily: [
      'Lexend', 'sans-serif'
    ].join(','),
    button: {
      fontWeight: 600,
    },
    h1: {
      fontWeight: 600
    },
    h2: {
      fontWeight: 600
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600
    },
    h5: {
      fontWeight: 600
    },
    h6: {
      fontWeight: 600
    },
  },
});
let laskuri: any =0;
let retryCount = 0;
let delay = 1000; // Initial delay in milliseconds

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState (false);
  const filters: any = useSelector((state: RootState)=> state.filters);
  const auth: any = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  
  let nameF = []
  let nameY: any = [];
  const [refresh, setRefresh] =useState(false);
  const [refreshTime, setRefreshTime] = useState(0);
  let workIdToken = '';

  interface UserProfile {
    // Määrittele UserProfile-tyyppi tarpeen mukaan
    credentials: any;
    authorities:[] | any
  }
  
  interface User {
    uid: string;
    email: string | null;
    providerData: { displayName: string }[];
  }
  
  interface DecodedTokenResult {
    (idToken: string, dispatch: any): Promise<UserProfile>;
  }
  
  interface Props {
    setLoading: (loading: boolean) => void;
    decodedToken: DecodedTokenResult;
  }
 
 
  useEffect(() => {
    const handleAuthStateChanged = async (user: firebase.User | null) => { // Käytetään firebase.User
      try {
        if (user) {
          await fetchUserTokenAndProfile(user);
        } else {
          dispatch(logout());
        }
      } catch (error: any) {
        console.error('Virhe firebaseAuth onAuthStateChanged -prosessissa:', error);
        dispatch(editFilterData({ error: true, errorMessage: 'Virhe firebaseAuth onAuthStageChanged -prosessissa. Ota yhteyttä järjestelmänvalvojaan.' }));
      } finally {
        dispatch(editFilterData({ loading: false }));
        setLoading(false);
      }
    };
    const unsubscribe = firebase.auth().onAuthStateChanged(handleAuthStateChanged);
    dispatch(editFilterData({ loading: true }));
    setLoading(true);

    // Tokenin automaattinen päivitys 55 minuutin välein
    const intervalId = setInterval(async () => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        try {
          const idToken = await currentUser.getIdToken(true);
          try{
            await decodedToken(idToken, dispatch);
            dispatch(updateIdTokenOnly(idToken));
            console.log("ID Token päivitetty:", idToken);
          } catch (error) {
            console.error("Virhe ID Tokenin päivittämisessä:", error);
            dispatch(editFilterData({ error: true, errorMessage: 'Virhe ID Tokenin päivittämisessä. Ota yhteyttä järjestelmänvalvojaan.' }));
          }         
        } catch (error) {
          console.error("Virhe ID Tokenin päivittämisessä:", error);
        }
      }
    }, 55 * 60 * 1000); // 55 minuuttia

    return () => {
      unsubscribe(); // Puhdista tilaustapahtuma
      clearInterval(intervalId); // Puhdista aikavälikutsu
      console.log('aikavälikutsu puhdistettu');
    };
  }, [dispatch]);

  const fetchUserTokenAndProfile = async (user: firebase.User)=> {
    try {
      let nameY = '';
      if (user.providerData && user.providerData.length > 0) {
        const displayName = user.providerData[0]?.displayName;
        if (displayName) {
          const nameParts = displayName.split(' ');
          if (nameParts.length > 1) {
            nameY = `${nameParts[nameParts.length - 1]} ${nameParts[0]}`;
            dispatch(setTextFilter(nameY));
          } else {
            nameY = displayName;
            dispatch(setTextFilter(nameY));
          }
        }
      }

      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken(true);
        const userProfile = await decodedToken(idToken, dispatch);
        dispatch(login(user.uid, idToken, user.email, nameY, userProfile));
      }
    } catch (error: any) {
      console.error('Virhe käyttäjän tunnistamisessa:', error);
      dispatch(editFilterData({ error: true, errorMessage: 'Virhe käyttäjän tunnistamisessa. Ota yhteyttä järjestelmänvalvojaan.' }));
    }
  };

  if (loading ) {
    return (
      <Box>
        <Typography sx={ subHeaderStyles }>{'Starting...'}</Typography>
        <CircularProgress color="secondary" />
        <CircularProgress color="success" />
        <CircularProgress color="inherit" />
      </Box>
    )};

  if (loading) {
    return (<div><CircularProgress/>..firebase loading</div>) };

  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    dispatch(editFilterData({error: false, errorMessage:''}));
  }

  if (loading ) return <div><CircularProgress /> <br />...header is  loading</div>
  // if (!auth.idToken ) return <div><CircularProgress /> <br />...idtoken loading</div>

  return (
    <Box>
      {
      (filters.error) &&
      <div>
        <Snackbar
          open={filters.error}
          autoHideDuration={10000}
          onClose={handleClose}
          message= {filters.errorMessage}
          
          />
      </div>
     }

    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex', border:'thin solid' }}>
      <Suspense fallback={ <div> Lataus<CircularProgress color="success" /></div>}>  
        <BrowserRouter>
          <Box sx={{ display: 'flex' }}>
            <AppMenuBar />;
            <SideNavigation />;
            <Box
              component="main"
              sx= {{ flexGrow: 1, p: { xs: 0, md: 7 }, mt: { xs: 4, md: 0 } }}
            >
              <Routes>
                <Route path="/" element= { < AuthenticationContainer />} />
                <Route path="/kirjaudu" element= { < LoginContainer />} />
                <Route path="/info" element= { <InfoContainer/> }  >
                  <Route index element={ <InfoPage />} />
                </Route>
                <Route path="/puhujat" element= { <SpeakersContainer/> } >
                  <Route index element={ <ListSpeakersPage />} />
                </Route>
                <Route path="/seuraohjelma" element= { <ServicesContainer/> } >
                  <Route path  = "/seuraohjelma" element={ <ListServiceProgramPage />} />
                  <Route path  = "/seuraohjelma/seuraohjelma" element={ <ListServiceProgramPage />} />
                  <Route path  = "/seuraohjelma/lisaarivi" element={ <AddServiceLine />} />
                  {/* <Route path  = "/seuraohjelma/analyysit" element={ <ServiceAnalyze />} /> */}
              
                </Route>
                <Route path="/calendar" element= { <CalendarContainer/> } >             
                  <Route path="/calendar/CheckCals" element= {  <CheckCals />} />     
                  <Route path="/calendar/calendar" element= {  <CalendarStart />} />    
                  <Route path="/calendar" element= {  <CalendarStart />} />   
                  <Route path="/calendar/Users" element= {  <AclListCalendar />} />          
                  {/* <Route path="/calendar/UpdateCals" element= { <UpdateCals />} /> */}
                </Route>
                <Route path="/servcrea" element= { <AddSpeakerToLineContainer/> } >
                  <Route index element={ <SetSpeakerToLine />} />
                </Route>
                <Route path="/reports" element= { <ReportsContainer/> } >
                  <Route index element={ <ServiceReports />} />
                </Route>
                
                           
                  <Route path="/drive" element= { <DriveContainer/> } >
                    <Route path = "/drive/drivelist" element = { <DriveListing/>} />
                    <Route path = "/drive" element={ <DriveStart />} />
                    <Route path = "/drive/drive" element={ <DriveStart />} />
                    <Route path = "/drive/groups" element={ <DriveGroups />} />
                    <Route path = "/drive/users" element={ <DriveUsers />} />
                  </Route>

                <Route path="/user" element= { <UserContainer/> } >
                  <Route path = "/user/userlist" element = { <UserListing/>} />
                  <Route path = "/user" element={ <UserComponent />} />
                  <Route path = "/user/user" element={ <UserComponent />} />
                  
                  <Route path = "/user/noRole" element={ <UserNoRoleComponent />} />
                </Route>
                {/* <Route path="/gmail" element= { <GmailContainer/> } >
                  <Route path = "/gmail/gmail" element = { <GmailStart/>} />
                </Route> */}
                <Route path="/profile" element= { <ProfileContainer/> } >
                  <Route path = "/profile/profile" element = { <ProfilePage/>} />
                  <Route path = "/profile/loadSqlTables" element = { <LoadSqlTables/>} />
                  <Route index element={ <ProfilePage />} />
                </Route>
                <Route path="/admin" element= { <AdminContainer/> } >
                  {/* <Route path  = "/admin/admin" element={ <AdminContainer />} /> */}
                  {/* <Route path  = "/admin/year" element={ <ChangeYear />} /> */}
                  <Route path  = "/admin/checkProgram" element={ <CheckServiceProgram />} />
                  <Route path = "/admin/CheckGroups" element ={ <GetFirebaseGroup/>}/>
                  <Route path = "/admin/CheckGroups/checkGroups" element ={ <GetFirebaseGroup/>}/>
                  {/* <Route path  = "/seuraohjelma/lisaarivi" element={ <AddServiceLine />} />
                  <Route path  = "/seuraohjelma/analyysit" element={ <ServiceAnalyze />} /> */}
              
                </Route>
               
              </Routes>
            </Box>
          </Box>
        </BrowserRouter>
        </Suspense>
      </Box>
    </ThemeProvider>
    </Box>
  )
}
export default App;