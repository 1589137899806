/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import moment from 'moment';
import { listPermissionOfDrive } from '../../services/sqlLiteServices';

const SubmitPermissionsOfDrive = async (token: any, dispatch: any, auth: any, driveId: any, driveName: any, inputDataBase: any, users: any) =>  {
  let drivesPermissions: any = [];
  const permissionList: any = await listPermissionOfDrive(token, driveId)
  let youAreOrganizer = false;
  await permissionList.reduce(async(earlier: any, permission: any) => {
    await earlier;
    if (!permission.deleted) {
      let permissionTxt = permission.emailAddress;
      permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
      if (permissionTxt === auth.email) {
        if (permissionTxt === 'organizer') {
          youAreOrganizer = true;
        }
      }

      const translatedPermissionValues = ['Sisällönhallinta','Ylläpitäjä','Katsoja', 'Tekijä','Kommentoija','Omistaja'  ]
      const translatedPermissionValue = translatedPermissionValues[['fileOrganizer','organizer','reader','writer','commenter','owner'].indexOf(permission.role)]
      permissionTxt = permission.emailAddress;
      permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
      let creationDate = users.find((user: any) => { if (user.primaryEmail === permissionTxt) return user.creationDate} )
      let organizer = false;
      if (permissionTxt === auth.email) organizer = true;
      let create = '';
      let lastlog='';
      if (permission.type !== 'group'){
        if (creationDate){
          create = creationDate.creationDate;
          lastlog = creationDate.lastLoginDate;
        }
        if (!creationDate) {
          create = moment('1970-01-01').format('D.M.YYYY')
          lastlog= moment('1970-01-01').format('D.M.YYYY')

        }}
      const pArray = ({
        id:permission.id,
        driveId : driveId,
        driveName:  permission.driveName,
        permissionId: permission.id,
        permissionType: permission.type,
        permissionRole: permission.role,
        translatedPermissionValues: translatedPermissionValue,
        type: permission.type,
        emailAddress:permission.emailAddress,
        role: permission.role,
        displayName: permission.displayName,
        creationDate:create,
        lastLoginDate : lastlog,
        organizer : organizer
      })
      drivesPermissions.push(pArray)
    }
  },{});
  return drivesPermissions
}
export default SubmitPermissionsOfDrive