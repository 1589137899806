/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Box,  IconButton, Snackbar, Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { VisibilityRounded, DeleteRounded } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import {  haveToReadDrive, editFilterData } from '../../actions/filters';
import { RootState } from '../../store/ConfigureStore';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import SidePanel from '../sidepanel/SidePanel';
import SidePanelLarge from '../sidepanel/SidePanel';
import { colors } from '../../styles/themeStylesSx';
import UpdatePermissions from './UpdatePermissions';
import DeletePermissions from './groups/DeletePermissions';
import groupServices from './../../services/groupServices';
import ListGroupMembersSimple from './groups/ListGroupMembersSimple';
import driveServices from '../../services/driveServices';
import { listPermissionOfDrive } from '../../services/sqlLiteServices';
import moment from 'moment';

type ListPermissionsType = {
  driveId?: any,
  close: () => void,
};

type DriveType = {
 kind: string,
 driveId: string;
 driveName: string,
 youAreOrganizer: boolean,
 emailAddress: string,
 name: string,
};

type DriveStateType = {
  openPerm?: boolean;
  open?: boolean;
  openDelete?: boolean;
  driveId?: string;
  add?: boolean;
  linenumber?: number;
  id?: any;
  permission?: any;
  openGroup?:boolean;
  group?: any;

};


const ListPermissions =  (props: ListPermissionsType) => {
  const dispatch = useDispatch();
  const drives = useSelector((state: RootState)  => state.drives);
  const filters = useSelector((state: RootState)  => state.filters);
  const groups = useSelector((state: RootState) => state.groups);
  const authorit: any = useSelector((state: RootState)  => state.auth);
  const users: any = useSelector((state: RootState) => state.users);

  const authUserId = users.find((usr: any) => {
    return authorit.email === usr.primaryEmail
  });

  const token = `bearer ${authorit.idToken}`;
  let driveActuel: any  = '';
  const [state, setState] = useState<DriveStateType>({ openPerm: false, openDelete: false, open: false, group: '' });
  const close = props.close;
  const [ loading, toggleLoading ] = useState(true);
  const [driveState, setDriveState] = useState<DriveType>();
  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  let result: any = [];
  let resultGroup: any = [];

  useEffect(() => {
    const areYouReally = async(  permissionc: any) => {
      const isInGroup = await groupServices
        .membersHasMember(token, permissionc!.emailAddress, authUserId.id)
        .catch( error => {
          console.error('Error fetching memberhasmember', error);
          setErrorState(true);
          setErrorMessage('Error in membersHasMember proc');
          return error;
        });
        permissionc.organizer = isInGroup;
        resultGroup.push(permissionc);  
    }

    if (drives.length === 0 ) {
      close()
    }
    let updatedPermissions: any = [];

    const createResults = async(driveActuel: any, drivesPermissions: any) => {
      let permissionList: any = [];
      //mfr read permissions of drive (all)
      //mfr if grouup ... 
      try {
        permissionList = await listPermissionOfDrive(token, driveActuel.id)
      }catch(error) {     
        console.error('Error fetching permissions', error)
        dispatch(editFilterData({error: true, errorMessage: ' Virhe ListPermissions, createResults, .listPermissions'}));
      };
      updatedPermissions = await permissionList.reduce(async(results: any, data: any) =>  {
        await results;
        if (data.deleted === 0) {
            const work = data;
          if (data.permissionType === 'group' ) {
            await areYouReally ( work )
          }
          else {
            if ((driveActuel?.youAreOrganizer && authorit.email === work.emailAddress) )  {
              work.organizer = true;
            } else {
              work.organizer = false;
            }
            result.push(work);
          };
          return result
        }
        }, Promise.resolve([]));
    
      return (updatedPermissions)
        
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const findDrive = async (driveId: any) => {
      const driveActuel = await drives.find((drive: any) => {
        return drive.id === props.driveId
      });
      if (driveActuel) {
        setDriveState(driveActuel);
        await createResults( driveActuel, driveActuel.drivesPermissions);
        const spread: any =  [...result, ...resultGroup];
        //setPermissionsInState(spread);
        dispatch(editFilterData({permissionsInState: spread}));
        toggleLoading(false);
        return driveActuel;
      }
    };
    if ( drives.length > 0)  {
      toggleLoading(true);
      findDrive(props.driveId);
    }
    dispatch (editFilterData({haveToReadDrive: false, driveLineEdited: false}))
    dispatch(haveToReadDrive(false))
  }, [ props.driveId,filters.haveToReadDrive, filters.driveLineEdited, driveActuel ])

  if (loading) return <div> <CircularProgress/> Loading permissions of drive... wait </div>

  if (!filters.permissionsInState) close();


  const permArray2 = filters.permissionsInState;
  permArray2.sort(( a: any, b: any ) => {
    var x: any = a.displayName
    var y: any = b.displayName
    if (x < y) {return -1}
    if (x > y) {return 1}
    else return  0
  })

  if (loading ) return <div><CircularProgress /> <br />venaa älähosu</div>
  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksia</div>)

  const TranslatePermission = (permissionRole: any) => {
    const translatedPermissionValues = ['Sisällönhallinta','Ylläpitäjä','Katsoja', 'Tekijä','Kommentoija','Omistaja'  ]
    const translatedPermissionValue = translatedPermissionValues[['fileOrganizer','organizer','reader','writer','commenter','owner'].indexOf(permissionRole)]
    return translatedPermissionValue;
  }

  const RetrieveCreationDate = (permission: any) => {
    let permissionTxt = permission.emailAddress;
    permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
    let creationDate = users.find((user: any) => { if (user.primaryEmail === permissionTxt) return user.creationDate} )
    let create = '';
    let lastlog='';

    if (creationDate){
      create = creationDate.creationDate;
      lastlog = creationDate.lastLoginDate;
    }
    if (!creationDate) {
      create = moment('1970-01-01').format('D.M.YYYY');
      lastlog= moment('1970-01-01').format('D.M.YYYY');
    }
    return create;
  }
  const RetrieveLastLoginDate = (permission: any) => {
    let permissionTxt = permission.emailAddress;
    permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
    let creationDate = users.find((user: any) => { if (user.primaryEmail === permissionTxt) return user.creationDate} )

    let lastLog='';

    if (creationDate){
  
      lastLog = creationDate.lastLoginDate;
    }
    if (!creationDate) {

      lastLog= moment('1970-01-01').format('D.M.YYYY');
    }
    return lastLog
  }



  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorState(false);
  }

  return (
    <Box>
      <SidePanel
       open={ state.openPerm === true }
       close={ () => setState({ openPerm: false, openDelete: false, open: false })}
      >
        <Box>
          {state.openPerm && 
            <UpdatePermissions driveId= {props.driveId } openDelete = {state.openDelete} permission= {state.permission} permissions= {filters.permissionsInState} close ={ () => setState({ open: false, openDelete: false })} />
          }
        </Box>
      </SidePanel>
      <SidePanel
       open={ state.openDelete === true }
       close={ () => setState({ openPerm: false, openDelete: false, open: false })}
      >
        <Box>
          {state.openDelete &&
             <DeletePermissions driveId= {props.driveId } openDelete = {state.openDelete}          
             permission= {state.permission} close ={ () => setState({ openPerm: false, openDelete: false, open: false })} />          
            }
       
        </Box>
      </SidePanel>
      <SidePanelLarge
        open={ state.open === true }
        close={ () => setState({ open: false })}
        >
          <Box>
            {state.open &&
              <ListGroupMembersSimple group= {groups.find((gro: any ) => gro.email === state?.permission?.emailAddress) }  close ={ () => setState({ open: false })} /> 
            }
          </Box>
        </SidePanelLarge>
      { errorState &&
        <div>
          <Snackbar
            open={errorState}
            autoHideDuration={6000}
            onClose={handleClose}
            message= {errorMessage}
            />
        </div>
      }
      <Typography sx={ subHeaderStyles }>{`${driveState?.name} Henkilöt / Ryhmät`}</Typography>

      <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            <TableCell>{'Nimi      '}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>

            <TableCell>{'Email'}</TableCell>
            <TableCell>{'Organizer'}</TableCell>
            {/* <TableCell>{'Type'}</TableCell>
            <TableCell>{'Role'}</TableCell>
            <TableCell>{'Luotu'}</TableCell>
            <TableCell>{'Viimeinen käyttö'}</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>

        { filters.permissionsInState.map((permissionc: any, i: number) => {

          const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
          return (
            <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${permissionc.id}_row6`}>
              <TableCell sx={{ backgroundColor }}>{ permissionc.displayName } </TableCell>
              <TableCell sx={{
                backgroundColor,
                borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
              <IconButton  onClick={() => setState({ openPerm: true, openDelete: false, permission: permissionc  })}><VisibilityRounded /></IconButton>
              </TableCell>
              { !permissionc.organizer ?
              <TableCell sx={{
                backgroundColor,
                borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                <IconButton onClick={() => setState({ openPerm: false, openDelete: true, permission: permissionc })}><DeleteRounded/></IconButton>
              </TableCell>
              :
              <TableCell>
              </TableCell>
              }
              { permissionc.type === 'group' ?
                <TableCell sx={{
                  backgroundColor,
                  borderTopRightRadius: 15, borderBottomRightRadius: 15 }} >
                  <IconButton onClick={() => setState({ openPerm: false, openDelete: false, open: true, permission: permissionc })}><ListIcon/></IconButton>
                </TableCell>
                :
                <TableCell>

                </TableCell>
              }
              
              <TableCell sx={{backgroundColor }}>{ permissionc.emailAddress }</TableCell>
              <TableCell sx={{backgroundColor }}>{ (permissionc.organizer === true) ? 'on' : 'ei' }</TableCell>
              <TableCell sx={{backgroundColor }}>{ permissionc.permissionType}</TableCell>
              {/* <TableCell sx={{backgroundColor}}>{ permissionc.translatedPermissionValues }</TableCell> */}
              <TableCell sx={{backgroundColor}}>{ TranslatePermission(permissionc.permissionRole) }</TableCell>

              {permissionc.permissionType === 'user' &&              
                // <TableCell sx={{backgroundColor}}>{ permissionc.creationDate }</TableCell>
                <TableCell sx={{backgroundColor}}>{ RetrieveCreationDate(permissionc) }</TableCell>
              }
              {permissionc.permissionType === 'user' &&   
                <TableCell sx={{backgroundColor}}>{ RetrieveLastLoginDate(permissionc) }</TableCell>
              }
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </Box>
  )
}

export default (ListPermissions)
