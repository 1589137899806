import React, { useState, useEffect}  from 'react';
import { Box, Checkbox , CircularProgress, FormControlLabel, IconButton,  Table, TableBody, TableCell, TableRow, TableHead, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { editFilterData } from "../../actions/filters";
import EditIcon from '@mui/icons-material/Edit';
import {RootState} from '../../store/ConfigureStore';
import { Navigate } from 'react-router-dom';
import submitGroupsToState from '../drives/groups/submitGroupsToState';
import readSheetDataFromFire from '../service/readSheetdataFromFire';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import { colors } from '../../styles/themeStylesSx';
import SidePanelMedium from '../sidepanel/SidePanelMedium';
import SidePanelMax from '../sidepanel/SidePanelMax';
import { ViewCompactAlt } from '@mui/icons-material';
import GetGroupmembersFromSheets from './GetGroupmembersFromSheets';
import submitUsersToState from '../drives/submitUsersToState';
import ListGroupMembersSimple from '../drives/groups/ListGroupMembersSimple';
import { FormControl, Radio, RadioGroup, TextField }  from '@mui/material';
import { sortByName } from '../../actions/filters';
import { useNavigate } from 'react-router-dom';


type GroupStateType = {
  open?: boolean;
  group?: any;
  listOwners?: boolean;
  ownerWaiting?: boolean;
  doRefresh?: boolean;
  groupId?: string;
  changeRole?: boolean;
  groupEmail?: any;
  groupName?: any;
  ownerName?: any;
  token?: any;
  compareServiceProgram?: any;
  listAll?: any;
  sortBy?: string;
  search?: string;
};

const GetFirebaseGroup =  ( ) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authorit: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const services: any = useSelector((state: RootState) => state.services);
  const groups: any = useSelector((state: RootState) => state.groups);
  const token = `bearer ${authorit.idToken}`;
  const [loading, setLoading] = useState(false);
  let refresh = 'no';
  const [state, setState] = useState<GroupStateType>({ open: false, group: '',  listOwners: false, doRefresh: false, groupId: '', changeRole: false, 
    compareServiceProgram: false, listAll: false,
    groupEmail: '', groupName: '', ownerName: '', token: token, sortBy: 'groupname', search: '' });
    
  useEffect(() => {
      if (!authorit.idToken) {
        navigate('/');
        return;
      }

    const process = async () => {
      try{
        submitUsersToState(token, dispatch);
        await readSheetDataFromFire( dispatch, filters, services, authorit, 'param');
      }
      catch(error) {
        console.log('error when reading sheetData from GetFirebaseGroups', error);
        dispatch (editFilterData({error: true, errorMessage: 'Problems in read Sheet from GetFirebaseGroupp'}));
        throw error;
      }
      
      try{
        if (state.doRefresh) {
          refresh = 'yes'
        }
        else {
          refresh = 'no'
        }
        await submitGroupsToState(token,authorit.uid, dispatch, refresh, filters, authorit);
        setLoading(false);
      }
      catch(error) {
        console.log('got error from reading firebase group', error);
        dispatch(editFilterData({error: true, errorMessage: 'something went wrong when readin groups'}));
        setLoading(false); 
      }
    }
    if (authorit.uid) {
      setLoading(true)
      process();
    }
  
  },[state.doRefresh, filters.somethchanged, authorit.idToken]);

  if (filters.loadingSubSheets)return (<div> <CircularProgress/> still loading serviceprogram subsheets, aodota hetki...</div>)

  if (loading) return (<div> <CircularProgress/> loading data for GroupChecks...</div>);

  const changeDoRefresh = (e: any) => {
    setState({...state, doRefresh : !state.doRefresh});
  }

  let workGroups =groups.filter ((gr: any) => {
    const wsearch = state.search ? state.search : ''; 
      return !gr.email.includes ('class') && ( gr.name.toLowerCase().includes(wsearch.toLowerCase()) || gr.email.toLowerCase().includes(wsearch.toLowerCase()) || gr.owner.toLowerCase().includes(wsearch.toLowerCase()));
  });
 
 const  key = state.sortBy? state.sortBy : 'name';
  workGroups.sort(( a: any, b: any ) => {

    var x: any = a[key]
      var y: any = b[key]
  
    if (x < y) {return -1}
    if (x > y) {return 1}
    else return  0
  });
  const handleChange = (e: any) => {
    setState({...state, sortBy: e.target.value});
  }; 
  const handleSearchChange = (e: any) =>  {
    const searchText = e.target.value;
  setState({...state, search: searchText})    
  }


  return  (
    <Box>
      <FormControlLabel
        control={
        <Checkbox
          checked= {!!state.doRefresh}
          onChange= {(e) => {changeDoRefresh(e)}}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      }
        label="Hae Ryhmät Google pilvestä!"
      />
      <Typography sx={ subHeaderStyles }>{`Helsingin Rauhanyhdistyksen  Google-Ryhmätt ${groups.length} kpl` }</Typography>
      <Box>
      <SidePanelMedium
        open = { state.changeRole === true }
        close={ () => setState({ ...state, changeRole: false })}
        >
        <Box>
          {state.changeRole &&
            <ListGroupMembersSimple 
            group = {state.group }  
            close ={ () => setState({ ...state, changeRole: false })}
            />
          }
        </Box>
      </SidePanelMedium>
      <SidePanelMax
          open = { state.compareServiceProgram === true }
          close={ () => setState({ ...state, compareServiceProgram: false })}
          >
          <Box>
            {state.compareServiceProgram &&
              <GetGroupmembersFromSheets 
              groupId = {state.groupId } 
              groupEmail= {state.groupEmail}  
              groupName= {state.groupName} 
              close ={ () => setState({ ...state, compareServiceProgram: false })}
              token= {state.token}
              ownerName= {state.ownerName}
              listAll = {state.listAll}
              //members = {members}
              />
            }
            </Box>
          </SidePanelMax>
        <Box>
          {services.length === 0 &&
          <Navigate to="/seuraohjelma" replace={true} />
          }       
        </Box>
        {(services.length === 0 ) &&
      
          <div>waitinf for more information</div>
        }
      </Box>
      <Box>
        <FormControl>
          <Typography 
          //  aria-owns = { open ? 'mouse-over-popover1' : undefined}
          //  aria-haspopup = "true"
          //  onMouseEnter={handlePopoverOpen1}
          //  onMouseLeave={handlePopoverClose}
          sx={ subHeaderStyles }>{'Lajittelu' }</Typography>
          <RadioGroup aria-label="gender" name="gender1" value={ state.sortBy} onChange={handleChange}>
            <FormControlLabel value="owner" control={<Radio />} label="Group-owner" />
            <FormControlLabel value="email" control={<Radio />} label="Group-email" />
            <FormControlLabel value="name" control={<Radio />} label="Grop-name" />
          </RadioGroup>
        </FormControl>
        <TextField
      
          style={{ minWidth: '150px'}}
          label="Rajaus"
          autoFocus
          name="rajaus"
          value={state.search}
          onChange= {handleSearchChange}
        />
      </Box>    
      <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            <TableCell>{}</TableCell>     
            <TableCell>{}</TableCell>
            <TableCell>{'Group-owner'} </TableCell>
            <TableCell>{'Group-email'} </TableCell>
            <TableCell>{'Grop-name'} </TableCell>
            {/* <TableCell>{'Grop-owner'} </TableCell> */}
            <TableCell>{'Grop-description '} </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workGroups.map((group: any, i: any) => {
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
            return  (
              <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {`${i}_row7`}>
                <TableCell sx={{
                  backgroundColor,
                  borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                  <IconButton onClick={() => setState({ ...state, changeRole: true,  group: group, token: token })}><EditIcon /></IconButton>
                                  
              </TableCell>
              <TableCell sx={{
                backgroundColor,
                borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>         
                <IconButton  onClick={() => setState({ ...state, changeRole: false, compareServiceProgram: true, groupEmail: group.email, groupId: group.id, ownerName: group.owner, groupName: group.name, token: token, listAll: true })}><ViewCompactAlt /></IconButton>
              </TableCell>
                <TableCell>{group.owner}</TableCell>
                <TableCell>{group.email}</TableCell>
                <TableCell>{group.name}</TableCell>
                <TableCell>{group.description}</TableCell>
              </TableRow>
          )
          })}
        </TableBody>
      </Table>

    </Box> 
  )
}
export default GetFirebaseGroup;