/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import userServices from '../../services/userServices'
import { emptyUsers, addAllUsers} from  './../../actions/users';
import moment from 'moment';

let userArray = [];
const submitUsersToState = async (token,dispatch) => {
  const userList = await userServices
    .userList(token)
    .catch( error => {
      console.error('Error fetching userlist', error)
    })
  await dispatch (emptyUsers({}));
  const allUsers = []
  await userList.forEach(  user => {
    const creationDate = moment(user.creationTime,'YYYY-MM-DDTHH:mm:ssZ').format('D.M.YYYY')
    const lastLoginDate = moment (user.lastLoginTime,'YYYY-MM-DDTHH:mm:ssZ').format('D.M.YYYY');

    userArray = ({
      creationDate: creationDate,
      lastLoginDate: lastLoginDate,
      primaryEmail:user.primaryEmail,
      id: user.id,
      name:user.name,
    })
    allUsers.push(userArray)

  })
 dispatch(addAllUsers(allUsers))
 return allUsers
}
export default submitUsersToState