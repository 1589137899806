import React, {useEffect, useState } from 'react';
import { useNavigate,Outlet, useLocation, Location  } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/ConfigureStore';
import { Container, Paper, Typography, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { headerStyles, simpleContainerPaperStyle, tabStyle, tabsStyle } from '../../styles/themeStylesSx';
//import useLocales from '../hooks/useLocales';

export default function ProfileContainer() {
  const auth: any = useSelector((state: RootState) => state.auth);
  const location: Location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('profile');
  const [state, setState] = useState<any>('')
  //const { t } = useLocales();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      if (newValue === 'profile') {newValue = ''}
      navigate(`/profile/${newValue}`);
      setValue(newValue);
    };
  useEffect(() => {
    const parts = location.pathname.split('/');

    const last = parts[parts.length - 1];
    if (last !== value) {
      setValue(last);
    };
    
  }, [location, value]);
    const windowWidth = window ? window.innerWidth : 1000;
    const [width, setWidth] = useState(windowWidth);
  useEffect(() => {
    setWidth(windowWidth);
  }, [windowWidth]);
  return (
    <Container>
      <Box sx = {{pt: 10}}>
        <Typography sx={ headerStyles }>{'Profiili sqlLite '}</Typography>
      </Box>
      <Tabs
        orientation={ width < 1000 ? 'vertical' : 'horizontal' }
        value={value === '' ? 'profile' : value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        sx={tabsStyle}
        aria-label = "admin tabs"
        >
        <Tab
          value="profile"
          label={'Profiili'}
          sx={ tabStyle }
      />

      <Tab
        value="loadSqlTables"
        label={'Lataa työtaulut'}
        sx={ tabStyle }
      />
      </Tabs>
      <Paper sx={ simpleContainerPaperStyle }>
          <Outlet />
      </Paper>
    </Container>
  );
}