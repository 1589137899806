import React, {useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {  Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, TextField, } from '@mui/material';
import {subHeaderStyles} from '../../styles/themeStylesSx';
import groupServices from '../../services/groupServices';
import { RootState } from '../../store/ConfigureStore';
import { addMembersToGroup } from '../../actions/groups';
import {editFilterData} from '../../actions/filters';
import database from '../../firebase/firebase';

type UpdatePropsType = {

  groupId: string;
  member: string;
  memberId: any;
  members: [];
  close(): void;
  name: string;
  memberName: any
  groupName: string;

};

const UpdateRole  = (props: UpdatePropsType) => {
  const [state, setState] = useState<any>({member: props.member, groupId: props.groupId,members: props.members});
  const auth : any = useSelector((state: RootState ) => state.auth);
  const token = `bearer ${auth.idToken}`;
  const close = props.close;
  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    const workMember = state.member;
    workMember.role = e.target.value;
    setState({...state, workMember})
  }

  const Update = async() => {
    try{
      await groupServices      
       .updateGroupMember(token,state.groupId, state.member.id, { role: state.member.role } );
      const workMembers = state.members.map((mem: any) => {
      if (mem.id === state.member.id ) {
        return state.member
      }
      else {
        return mem
      }
      });
      if (state.member.role === 'OWNER') {
        database.ref(`users/${auth.uid}/groups/${state.groupId}`).once("value")
        .then((snapshot: any) => {
            if (snapshot.exists()) {
              database.ref(`users/${auth.uid}/groups/${state.groupId}`).update({
                owner: props.memberName, 
                usersId: props.memberId
            });
          } else {
            console.error("Virhe: Solmua ei löydy, varmista että se on luotu ennen päivitystä!");
            dispatch(editFilterData({error: true, errorMessage: 'Tsekkkaa paprametrit UpdateRole-kompossa'}))
          }
        });
        dispatch(editFilterData({somethchanged:  Math.random()}))
      };

      dispatch(addMembersToGroup(state.groupId, workMembers));
      dispatch(editFilterData({somethchanged : Math.random(), error: true, errorMessage: 'Rooli Päivitetty'}));
      close();
   
    }catch(error) {
      console.log('error update in role in UpdateRole' , error);      
      dispatch(editFilterData({error: true, erroroMessage: 'virhe roolin päovityksessä'}))
      setState({ ...state, updateOk: false});
    }   
  }

  return (
    <Box>
      <Box>
        <Typography sx= {subHeaderStyles}> {`Ryhmän ${props.name} henkilön: ${props.memberName}  roolin päivittäminen` }</Typography>
      </Box>

      <Box>
        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'email'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ props.memberName}
        />
        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'GroupName'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ props.name}
        />
        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'Rooli'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ state.member.role}          //onChange={(e: any) => handleChange('newLinePm', e.target.value)}
        />
        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'Type'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ state.member.type}
        />
        <Box>
          <Typography sx= {subHeaderStyles}> {`Henkilön: ${props.memberName}  uusi rooli` }</Typography>
        </Box>
        <Box>
          <FormControl  fullWidth = {true}>
            <InputLabel>Valitse Rooli</InputLabel>
            <Select
              labelId="selectRole"
              value={state.member.role}
              name="rolechange"
              onChange={handleChange}
            >
              <MenuItem value="OWNER">   Omistaja    </MenuItem>
              <MenuItem value="MANAGER">   Manageri </MenuItem>
              <MenuItem value="MEMBER"> Jäsen</MenuItem>
              <MenuItem value = " "> </MenuItem>
            </Select>

          </FormControl>

        </Box>
        <Box>
          <Button variant= "contained" color="primary" 
          onClick = {(e) => {Update()}}> Päivitä rooli</Button>

        </Box>

      </Box>
    </Box>
  )
} 
export default UpdateRole;