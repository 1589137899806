/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import groupServices from '../../services/groupServices'

const submitListMembersOfGroup = async (token,id) => {
  
  try {
    const groupMembers = await groupServices
      .groupMemberList(token,id);
     return groupMembers;
  } catch( error) {
    console.error('Error fetching groupmembers', error);
    throw error;
  } 
  
}
export default submitListMembersOfGroup