/* eslint-disable semi */
/* eslint-disable linebreak-style */
//import React, { useState, useEffect }  from 'react'
import driveServices from '../../services/driveServices';
import { editFilterData } from '../../actions/filters';
let drivesPermissions: any = [];
const submitPermissionDeleteOfDrive = async(token: any, driveId: any,
  permission: any,
  uid: any,
  role: any,
  dispatch: any,
filters: any) => {
  try {
    const replyOfDelete = await driveServices  
      .permissiondelete(token,driveId, permission.id);
      if (replyOfDelete === 403) {
        dispatch(editFilterData({error: true, errorMessage: 'Ei tarvittavia oikeuksia toimenpiteeseen!'}))
      }else {
        if (replyOfDelete !== 'ok') {
          dispatch(editFilterData({error: true, errorMessage: 'Jäsenen poisto ei onnistunut!!!!!'}))
        } else {
          const newPermissions = filters.permissionsInState.filter((fi:any) =>  fi.id !== permission.id)
          dispatch (editFilterData({permissionsInState : newPermissions}));
          dispatch(editFilterData({error: true, errorMessage: 'Jäsenen poisto onnistui'}))
        }
      } 
  }
  catch(error)  {
    console.log('error when deleting permission submitPermissionDeleteOfDrive', error);
    dispatch(editFilterData({error: true, errorMessage: 'Jäsenen poisto ei onnistunut!!!!!'}));
  }
  return drivesPermissions;
}
export default submitPermissionDeleteOfDrive;