/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
export default (state = {}, action) => {
  switch (action.type) {
  case 'LOGIN':
    return {
      uid: action.uid,
      idToken:action.idToken,
      email:action.email,
      speakerName :action.speakerName,
      userProfile: action.userProfile
    };
    case 'UPDATE_IDTOKEN':
      return {
        ...state,
        idToken: action.idToken,
        userProfile: action.userProfile
      }
      case 'UPDATE_IDTOKENONLY':
        return {
          ...state,
          idToken: action.idToken,
        }
  case 'LOGOUT':
    return {};
  default:
    return state;
  }
}