
import programService from "../../services/programService";
import createSpeakersState from "../speakers/createSpeakersState";
import { editFilterData, setLoading, setSheetYears } from "../../actions/filters";
import { speakersToStateOnce } from "../../actions/speakers";
import { loadOnce } from "../../actions/services";
import moment from "moment";
let currentSheet: any = {};
let sheetYears: any = [];
export const ReadServiceAndSpeakersToRedux = async(dispatch: any, token: any, serviceYearParam: any, filters: any, authorit: any)=> {
  const getSpeakers = async (speakers: any) => {
    const speakersArray = await createSpeakersState(speakers);
    dispatch(speakersToStateOnce(speakersArray));
  }

  const retr1 = async (token: any, services1: any, speakers: any ) => {    
    getSpeakers(speakers);
    dispatch(loadOnce(services1));       
    if (services1.length > 0 ) {
      const servicesWork = [...services1]
      if (servicesWork.filter((work: any) => work.sspeaker.toLowerCase().includes(filters.text.toLowerCase())).length === 0){
        dispatch(editFilterData({text : ''}));
      }  
    }
    return services1;
  }
  const getSheetId = async () => {
    let insurances: any = [];
    let speakers: any = [];
    let modifiedTime: any = '';
    let programName: any = '';  
    
    if ( authorit.idToken ) {
      try {
        const sheetId1 = process.env.REACT_APP_SHEET_ID;
        const initialObject = await programService.getFirebaseData(token, serviceYearParam, sheetId1, authorit.uid, authorit.email);
        if (initialObject === 'Error in getFirebaseData') {
          console.log('error found in initialObject', initialObject);
          dispatch(editFilterData({error: true, errorMessage: 'Seuraohjelman latausvirhe ReadServicesAndSpeakersFromRedux'}));
        }
        else {        
          insurances = initialObject.insurances;
          modifiedTime = initialObject.modifiedTime;
          programName = initialObject.programName;
          speakers = initialObject.speakers.map((ins: any) => {
            return ins.line
          });
      
          const current = initialObject.sheetYears.find((key: string) => {
            return key[0] == serviceYearParam;
          });
          let workStartDate: any = '';
          let workEndDate: any = '';
          if (filters.lineAdded) {
            workStartDate = filters.startDate;
            workEndDate = filters.endDate;
          }  
          else {
            workStartDate = moment(filters.startDate).isSameOrBefore (moment(serviceYearParam,'year')) ? moment([serviceYearParam]) : moment().startOf('week');
            workEndDate = moment(filters.startDate).isSameOrBefore (moment(serviceYearParam,'year')) ? moment([serviceYearParam]).add(374,'day') :  moment().endOf('year').add(10,'days');
          }
          currentSheet = ({
            sheetId: current[1],
            range: current[2],
            columnSpeaker: current[4], //columnSpeaker,
            columnAddInfo: current[3], //.columnAddInfo,
            subSheet: current[5],      // ?.subSheet,
            speakerSubColumn: current[6], //?.speakerSubColumn,
            speakerSubDayCol1: current[7], // .speakerSubDayCol1,
            speakerSubDayCol2: current[7], // ?.speakerSubDayCol1,
            subjectColumn:current[6], // ?.subjectColumn,
            serviceYear: serviceYearParam.toString(),
            startDate: workStartDate,
            endDate: workEndDate
          });
          
          dispatch(editFilterData({ currentSheet, startDate: currentSheet.startDate, endDate: currentSheet.endDate }));
          await dispatch(setSheetYears(initialObject.sheetYears));
          sheetYears = initialObject.sheetYears;
        }
      }
      catch(e) {
        console.log('Error when reading firebasedata', e);        
        dispatch(editFilterData({error: true, errorMessage: 'Seuraohjelman latausvirhe firebasesta ReadAndStoreServiceProgram'}));
        return
      }
      dispatch(editFilterData({lastChangeTimestamp: modifiedTime.modifiedTime,  serviceProgramName: programName }));
      await retr1(token, insurances, speakers );
      dispatch(setLoading(false))
    }    
  }
  dispatch(setLoading(true));
  await getSheetId()
  const returnable = ({
    currentSheet: currentSheet,
    sheetYears: sheetYears
  });
 return returnable;
}