/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { Box, CircularProgress, Typography } from '@mui/material';

import { RootState } from '../../store/ConfigureStore';
import { lineStyles } from '../../styles/themeStylesSx';
import { editFilterData } from '../../actions/filters';
import submitListMembersOfGroup from '../drives/submitListMembersOfGroup';
import HandleDifferences from './HandleDifferences';

type readPersons = {
  groupName: string;
  groupEmail: string;
  groupId: any;
  ownerName: string;
  close: () => void;
  token: string;
  listAll: boolean;
  //members: any;
 
};
type GroupStateType = {
  open?: any;
  groupId?: any;
  close?: boolean;
  memberId?: string;
  memberName?: any;
  groupName?: any;
  group?: any;
  lineStart?: any
  mouseMove? : any;
  startLine?: any;
  endLine?: any;
  criterias: any,
  serviceProgramSheetId: any;
  sheetToAnalyze: any;
  compareGroup: boolean;
  groupEmail: string;
  sheetGroup? : any[],
  googleGroup?: any[];
  members?: any;

};

const GetGroupmembersFromSheets =  (props: readPersons ) => {
  const [loading, toggleLoading] = useState(false);
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState) => state.filters);
  const auth: any = useSelector((state: RootState) => state.auth);
  const groups: any = useSelector((state: RootState) => state.groups);
  const [sheetSt, setSheetSt] = useState<any>([])
  const [memberSt, setMemberSt] = useState<any>([]);
  const [isMounted, setIsMounted] = useState(true);
  const [state, setState] = useState<GroupStateType>({
    groupId: props.groupId, 
    close: false, 
    mouseMove: false,  
    criterias: {
      startColumn: '',
      endColumn: '',
      startLine: '',
      endLine: '',
      subSheet: '',
      firstLine:'',
    },
    serviceProgramSheetId: '',
    sheetToAnalyze: '', 
    groupEmail:'',
    compareGroup: false,
    sheetGroup: [], 
    googleGroup: [],
    members:[]  
  });
  const token = `bearer ${auth.idToken}`;

  useEffect(() => {
    setIsMounted(true);
    const membersFunc = async (id: any ) =>  {
      let memberList : any = [];
      const currentGroup = groups.find((gr: any) => gr.id === id);
      if (currentGroup.members) {
        memberList = currentGroup.members;
      }    
      else {     
        try{
          memberList = await submitListMembersOfGroup(token, id);
        } catch(error) {
          console.error('erroro from membersfunc in listgroupmembelight', error);
          dispatch(editFilterData({error: true, errorMessage: 'TArkista ohjelman kutsuparametrit'}));
        }  
      }
      const work = await memberList!.sort((a: any, b: any) => {
        var x = a.email
        var y = b.email
        if (x < y) {return -1}
        if (x > y) {return +1}
        if (x === y) {return 1}
      });
      return work;     
    } 

    const fetchMembers = async () => {
      if (state.groupId) {
        const currentGroup = await groups.find((gr: any) => gr.id === state.groupId);
        if (currentGroup.members) {
          const memberData = await membersFunc(state.groupId);            
          if (isMounted) {
            setState({ ...state, members: memberData}); // Tallennetaan jäsenet stateen
            setMemberSt(memberData)
          } 
        }
        else {
          try{
            const workMembers = ( await submitListMembersOfGroup(token, state.groupId));
            setState({ ...state, members: workMembers});
            setMemberSt(workMembers);          
          } catch(error) {
            console.error('erroro from membersfunc in listgroupmembelight', error);
            dispatch(editFilterData({error: true, errorMessage: 'TArkista ohjelman kutsuparametrit'}));
          }  
        } 
      }
    };

    fetchMembers();
    
    return () => {
      setIsMounted(false); // Kun komponentti unmountataan, merkataan se epäaktiiviseksi
    };
  }, [props.groupId]); // Suoritetaan aina kun groupId muuttuu
  
  useEffect(() => {
    const readParameters = async () => {
      // Puretaan tarvittavat parametrikentät
      const {
        seuraIsannat,
        kassat,
        saestajat_ja_esilaulajat,
        valvojat,
        aanitarkkailijat,
        tulkit,
        Toimikunnat_ja_alaryhmät,
      } = filters.parameters;
      // Yhdistetään kaikki parametrilistat yhdeksi taulukoksi
      const allParameters = [
        ...seuraIsannat,
        ...kassat,
        ...saestajat_ja_esilaulajat,
        ...valvojat,
        ...aanitarkkailijat,
        ...tulkit,
        ...Toimikunnat_ja_alaryhmät,
      ];

      const emailRowIndex = allParameters.findIndex((row: any) => {
        const emailList = row.length > 2 ? row[2].split(';') : [];
        return emailList.includes(props.groupEmail);
      });
      // Otetaan taulukon loppuosa alkaen löydetystä indeksistä
      const workk = allParameters.slice(emailRowIndex);
      let workk1: any[] = [];
      // Jos workk sisältää rivin, jonka ensimmäinen kenttä sisältää '/**'
      const indexRow0 = workk.findIndex((row: any) => row[0].includes('/**'));
      if (indexRow0 !== -1) {
        // Etsitään se indeksi, jossa toisen kentän sisältö sisältää '/**'
        const indexRow1 = workk.findIndex((row: any) => row[0].includes('/**'));
        workk1 = workk.slice(0, indexRow1);
      };
     
      setState({...state, sheetGroup: workk1});
      setSheetSt(workk1);
      toggleLoading(false);
    };

    toggleLoading(true); 
    readParameters()   ;
   
  },[props.groupId, filters.somethchanged ]);
  if (filters.loadingSubSheets) return ( <div> latailee vielä subsheet-parametreja <CircularProgress/> </div>) 
  if (loading) return ( <div> lataa groupsmembereitä <CircularProgress/> </div>)  
  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksiaa</div>);

  return (
    <Box>
      <Typography sx={ lineStyles }>{` Googleryhmä:${props.groupEmail} / Seuraohjelma: ${filters.serviceYear}, ${state.sheetToAnalyze}  ` }</Typography>
      <Box>
        <HandleDifferences 
        groupEmail = { props.groupEmail}
        groupName = {props.groupName}
        groupId ={props.groupId}
          sheetGroup={sheetSt} 
          googleGroup={state.googleGroup} 
          close={props.close} 
          members= {memberSt}
        />
      </Box>
    </Box>
  )
}
export default GetGroupmembersFromSheets