/* eslint-disable linebreak-style */
/* eslint-disable no-extra-semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
const drivesInitialState = []


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = drivesInitialState, action) => {
  switch (action.type) {
  case 'ADD_DRIVES':
    return [
      ...state,
      action.drivesData
    ];
    case 'ADD_DRIVES_ONCE':
      state = [];
      return action.driveArray.driveArray.map(serv => {
        return serv;
       })

  case 'EMPTY_DRIVE':
    return  drivesInitialState;

  case 'EDIT_YOU_ARE_ORGANIZER':
    return  state.map((drive) => {
      if (drive.driveId === action.driveId.driveId) {
        return {
          ...drive,
          youAreOrganizer:action.driveId.youAreOrganizer
        };
      } else {
        return drive;
      }
    });

    case 'EDIIT_PERMISSION':
      return  state.map((drive) => {
        if (drive.driveId === action.driveId) {
          return {
            ...drive,
            permission:action.permissions

          };
        } else {
          return drive;
        }
      });

  case 'EMPTY_DRIVES':
    return drivesInitialState

  default:
    return state
  }
}


