/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import { Button, Paper, TextField, Typography, Box } from "@mui/material";
import React from 'react';
import { connect } from  'react-redux';
import { startLogin } from '../actions/auth'
import { mobileWidth50 } from "../layout";
// eslint-disable-next-line react/prop-types
export const LoginPage = ({startLogin}) => (
  <Paper sx={{ p: 3 }}>
    <Box sx={{ textAlign: 'center', mb: 1 }}><Typography variant="h5">Kirjaudu</Typography></Box>
    <Box sx={{
      display: {
        md: 'flex',
        xs: 'block',
      },
      flexDirection: 'row'
    }}>
      <Box sx={{
        ...mobileWidth50,
        borderRight: {
          // md: 'thin solid gray',
          xs: 'none',
        },
        pr: 2
      }}>
        {/* <Typography variant="h6">Hry:n portaalipalvelut</Typography> */}
        <p></p>
        <Button variant="contained" color="primary" onClick = {startLogin} >Login </Button>
      </Box>
    </Box>
  </Paper>
);
const mapDispatchToProps = (dispatch) => ({
  //startLogin: () => dispatch(startLogin())
  startLogin: () => dispatch(startLogin())
});
export default connect(undefined, mapDispatchToProps) (LoginPage);