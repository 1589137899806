/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable linebreak-style */
import React, {Component} from 'react'
import { connect } from 'react-redux'
import { FormControl, Select, InputLabel, MenuItem, TextField, Box, FormControlLabel, Checkbox} from '@mui/material';
import {  setTextFilter, setListAll, editFilterData } from '../../actions/filters';
import {DateRangePicker} from 'react-dates';
import moment from 'moment';
import {Tooltip} from '@mui/material';
import { ReadServiceAndSpeakersToRedux } from '../genUtilities/ReadServiceAndSpeakersToRedux';
import { GenGetAllSubSheetsToFire } from '../genUtilities/GenGetAllSubSheetsToFire';



class ServiceListFilters extends Component <any, any>{
  state = {
    calendarFocused:null,
    selSpeaker : '',
    speaker:this.props.filters.text,
    saveSpeaker: this.props.filters.text,
    checked: false,
    checkedQuest: false,
    checkedAll:false,
    workYear : this.props.filters.serviceYear,
    token : `bearer ${this.props.auth.idToken}`,
    hryTilat:false,
    keittiö:false,
    startDate:this.props.filters.startDate,
    endDate:this.props.filters.endDate,
    checkedToFile : false,
    ButtonStyle: 'secondary',
    currentYear : moment().year(),
  }
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  };

  componentWillUnmount() {
    this._isMounted = false;
  };
  
  onServiceYearChange = async (e: any) => { 
    this.props.dispatch(editFilterData({serviceYear: e.target.value} ));
    try{
      const returned: any = await ReadServiceAndSpeakersToRedux (this.props.dispatch, this.state.token, e.target.value, this.props.filters, this.props.auth);
      await GenGetAllSubSheetsToFire( this.props.dispatch, returned.currentSheet.sheetId, e.target.value, this.state.token, this.props.auth);
      //this.props.dispatch(editFilterData({parameters : []}));
      if (this._isMounted ) {
        this.setState({ startDate: returned.currentSheetRet.startDate,  endDate: returned.currentSheetRet.endDate});
      }
    } catch (error) {   
      console.log('Error in onServiceYearChange', error);
    }
  }

handleCheckboxChange = (event: any) => {
  if (this.state.checked === true) this.setState(() => ({checked:false}))
  else {
    this.setState(() => ({ checked: true }));
  }

  if (this.state.checked === false){
    this.props.dispatch(setTextFilter(''))
    this.setState(() => ({speaker:''})) }
  else {
    this.props.dispatch(setTextFilter(this.state.saveSpeaker))
    this.setState(() => ({speaker:this.state.saveSpeaker}))
  }
};
handleCheckboxChangeQuest = (event: any) => {
  if (this.state.checkedQuest === false) this.setState(() => ({checkedQuest:true}))
  else {
    this.setState(() => ({ checkedQuest: false }));
  }

  if (this.state.checkedQuest === true){
    this.props.dispatch(setTextFilter(''))
    this.setState(() => ({speaker:''})) }
  else {
    this.props.dispatch(setTextFilter('Vieras'))
    this.setState(() => ({speaker: 'Vieras'}))
  }
};
handleCheckboxChangeAll = (event: any) => {
  this.props.dispatch(setListAll(!this.state.checkedAll))
  if (this.state.checkedAll === true) {
    this.setState(() => ({checkedAll:false}))
  }
  else {
    this.setState(() => ({ checkedAll: true }));
  }

  if (this.state.checkedAll === false){
    this.props.dispatch(setTextFilter(''))
    //
    this.setState(() => ({speaker:''})) }
  else {
    this.props.dispatch(setTextFilter(this.state.saveSpeaker))
    this.setState(() => ({speaker:this.state.saveSpeaker}))
  }
};
  onDatesChange = ({startDate,endDate}: any) => {
    const work = this.props.filters.currentSheet;
    work.startDate = startDate;
    work.endDate = endDate;
    this.props.dispatch(editFilterData({ startDate: work.startDate }));
    
    this.props.dispatch(editFilterData({ endDate: work.endDate }));
    this.setState({startDate: startDate});
    this.setState({endDate: endDate});
    
  }
  onFocusChange = (calendarFocused: any) => {
    this.setState(( )=> ({calendarFocused}))
  }
  onChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(() => ({ [name]: value }));
  }
  handleCalChange = (e: any) => {
    const name = e.target.name;
    let value = e.target.checked;
    this.setState(() => ({ [name]: value }));
  }
 
  render () {
    return (
      <Box sx={{borderBottom:'thin solid', pt:1, pb:2}}>
        <Box sx={{borderBottom:'thin solid', pt:1, pb:2}}>
          <Box>
            <Tooltip title = "Kirjoita puhujan nimeä" arrow>
              <TextField
                label="Puhuja"
                variant = "standard"
                sx={{ maxWidth: '100px'}}
                // autoFocus
                name="speaker"
                value={this.props.speaker}
                onChange={(e) => {
                  this.props.dispatch(setTextFilter(e.target.value))
                }}
              />
            </Tooltip>
            <FormControl>          
              <InputLabel> Puhujat</InputLabel>
              <Tooltip title = "Valitse puhuja listalta" arrow>
                <Select
                  sx={{ ml:1, mr:1 , minWidth: '100px'}}
                  variant = "standard"
                  labelId="selectSort"
                  value = { this.state.selSpeaker }
                  name="selspeaker"
                  onChange={(e: any) => {
                    this.props.dispatch(setTextFilter(e.target.value))
                    this.setState(() => ({selSpeaker: e.target.value}))
                  }}
                >
                {
                this.props.speakers.map( (speaker: any) =>
                  <MenuItem
                    key={speaker.linenumber}
                    value={speaker.speaker}
                  >
                    { speaker.speaker }
                  </MenuItem>
                )
                }
                </Select>
              </Tooltip>
            </FormControl>
          
            <DateRangePicker
              startDate = {this.state.startDate}
              endDate = {this.state.endDate}
              startDateId="MyDatePicker"
              endDateId="your_unique_end_date_id"
              onDatesChange={this.onDatesChange}
              focusedInput={this.state.calendarFocused}
              showClearDates ={true}
              onFocusChange={this.onFocusChange}
              numberOfMonths ={1}
              isOutsideRange = {() => false}
              firstDayOfWeek={1}
              minimumNights={0}
              displayFormat = "DD.MM.YYYY"
            />
        
            {this.props.filters.sheetYears.length > 0 &&
            <Tooltip title = 'Valitse seuraohjelman vuosi ' arrow placement="right">
              <FormControl  style={{ marginTop: '-5px' }}>
                <Select
                  style={{ minWidth: '40px', marginLeft:'10px'}}
                  labelId="selectSort"
                  value = { this.props.filters.serviceYear }
                  onChange={(e) => {
                    this.onServiceYearChange(e)
                  }}
                >
                  <MenuItem value= { this.props.filters.sheetYears[this.props.filters.sheetYears.findIndex((line: any) => {
                    return  line[0] == this.state.currentYear;
                    })][0]
                    }
                    >
                    { this.props.filters.sheetYears[this.props.filters.sheetYears.findIndex((line: any) => {
                    return  line[0] == this.state.currentYear })][0] }
                  </MenuItem>
                  <MenuItem value= { this.props.filters.sheetYears[this.props.filters.sheetYears.findIndex((line: any) => {
                    return  line[0] == this.state.currentYear }) + 1][0] }
                  >
                    {this.props.filters.sheetYears[this.props.filters.sheetYears.findIndex((line: any) => {
                      return  line[0] == this.state.currentYear })+1][0] }
                  </MenuItem>
                </Select>
              </FormControl>
              </Tooltip>
            }
          </Box>             
        </Box > 
        <Box sx={{ mt: 1 }}>
          <FormControlLabel
            control={
              <Tooltip title= "Näytä kaikki puhujat" arrow>
                <Checkbox
                checked={this.state.checked}
                onChange={this.handleCheckboxChange}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              </Tooltip>
            
            } label= "Kaikki"
          />
          <FormControlLabel
            control={
              <Tooltip title= "Näytä Vieras puhuja-rivit" arrow>
                <Checkbox
                  checked={this.state.checkedQuest}
                  onChange={this.handleCheckboxChangeQuest}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Tooltip>

            } label = "Vieras"
          />
      
          <FormControlLabel
            control={
              <Tooltip title= " Näytä kaikki rivit" arrow>
                <Checkbox
                  checked={this.state.checkedAll}
                  onChange={this.handleCheckboxChangeAll}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Tooltip>
              
            } label = "Kaikki rivit"

          />
          
        </Box>        
      </Box>
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    filters: state.filters,
    speakers: state.speakers,
    auth:state.auth
  }
}
export default connect(mapStateToProps) (ServiceListFilters)