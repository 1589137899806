/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable linebreak-style */
/* eslint-disable no-extra-semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
const servProgReducersInitialState = []


export default (state = servProgReducersInitialState, action) => {
  switch (action.type) {
  case 'SET_SPEAKER':
    return [
      ...state,
      action.service
    ];
  case 'ADD_SERVICE_PROGRAM_ONCE':
    state = [];
    return action.serviceList.map(serv => {
    return serv;
    })
  case 'EDIT_SERVICE':
    return  state.map((serv) => {
      if (serv.id === action.id) {
        return {
          ...serv,
          ...action.updates
        };
      } else {
        return serv;
      }
    });

  default:
    return state
  }
}

