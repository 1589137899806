
let callTable: any[] = [];
export const getSubsheetIds = async(filters: any) => {
  const serviceSheetId = filters.sheetYears.filter((row: any) => {
    return row[0] == filters.serviceYear;
  });
  const subSheetIds = filters.sheetYears.filter((row: any) => {
    return row[1] === serviceSheetId[0][1];
  });
  subSheetIds.forEach(((fi: any) => {
    const ar1 = fi[5]
    if (!callTable.includes(fi[5]) && !fi[5].includes('Seurakalenteri') && !fi[5].includes('Puhujat')) {
      callTable.push(fi[5]);
    }
  }));
  return callTable;
};