/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import submitGenCalUpd from '../calendars/submitGenCalUpd';
import programService from '../../services/programService';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import moment from 'moment'
import { editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import { Box, Button, FormControl, FormControlLabel, Switch, InputLabel, MenuItem, Select, Snackbar,  TextField, Typography, CircularProgress } from '@mui/material';
import {ServiceLineType} from './../../types/serviceLine';
import submitGetCalendarIdArr from '../calendars/submitGetCalendarIdArr';
import { startEditServiceProgram } from '../../actions/services';
import readSheetDataFromFire from './readSheetdataFromFire';
import { setCalendarIds} from '../../actions/filters';

type UpdateServiceLineComponentType = {
  serviceId?: any,
  close: () => void,
};

const UpdateServiceLine = (props: UpdateServiceLineComponentType) => {
  const close = props.close;
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  const services: any = useSelector((state: RootState)=> state.services);
  const auth: any = useSelector((state: RootState) => state.auth);
  const [ loading, toggleLoading ] = useState(false);
  const token = `bearer ${auth.idToken}`;

  const [state, setState] = useState<ServiceLineType>({
     serviceId: '',
    type: '',
    excluded: '',
    Vko: 0,
    Vkonpäivä: '',
    StartDate: moment().format('D.M.YYYY'),
    StartTime: '',
    EndDate: moment().format('D.M.YYYY'),
    EndTime: '',
    Pm: '',
    subject: '',
    Selite: '',
    Location: '',
    Toimikunta: '',
    Private: '',
    sspeaker: '',
    Seuraisäntä: '',
    Säestäjä: '',
    Esilaulaja: '',
    Valvontaryhmänvetäjä: '',
    Englannintulkkaus: '',
    Eestintulkkaus: '',   
    Viittomakielentulkkaus: '',
    Äänentoisto: '',       
    Siivousryhmä: '',
    Kassaryhmä: '', 
    linenumber: 0,
    strike: '', 
    email: auth.email,  
    PmTrueFalse: false,
    originalLocation: '',
    originalStartDate: '',
    originalStartTime: '',
    originalEndDate: '',
    originalEndTime :'',
    originalSubject : '',
    originalSelite: '',
  })

  useEffect (() => {
    if (filters.loading) {
     <CircularProgress/>
    }
    const findService = async (id: any) => {
      const service = await services.find((ser: any) => ser.id === id );
      setState({ ...service, 
        PmTrueFalse: service.Pm === 'k' ? true :false,
        originalLocation: service.Location, 
        originalStartDate: service.StartDate, 
        originalStartTime : service.StartTime,
        originalEndDate: service.EndDate,
        originalEndTime: service.EndTime,    
        originalSubject : service.subject  
      });
      toggleLoading(false)
    };

    toggleLoading(true);
    if (props.serviceId !== undefined) {
      findService(props.serviceId);
    };    
  },[props.serviceId])  
  
  useEffect(() => {
    if (props.serviceId === undefined) {
      close()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.serviceId])

  useEffect(() => {
 
    const fetchData1 = async () => {
      try{
        if (services.length === 0) {
          close();
        }
        if (!filters.parameters.typesValues) {
          const parameters = await readSheetDataFromFire( dispatch, filters, services, auth, 'param');      
          const calendarDataArray1 = await submitGetCalendarIdArr(token);
          dispatch(setCalendarIds(calendarDataArray1));
          //dispatch(editFilterData({ loading: false}));
          toggleLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(editFilterData({ error: true, errorMessage: 'Virhe hakemassa tietoja' }));
        toggleLoading(false);
        //dispatch(editFilterData({ loading: false}));
      } 
    };
    toggleLoading(true);
    //dispatch(editFilterData({loading: true}));
    if (services.length === 0) {
      close()
    }
    else{
       fetchData1();
    }
   
  
    // Dependency array for useEffect
  }, [filters.serviceYear]);
    
  if (loading ) return   <div><CircularProgress /> <br /> subsheetdata of serviceprogram latailee vielä</div>


  const  handleChange1 = async (e: any) => {
    if (e.target.checked) {
      setState({ ...state, Pm : 'k', PmTrueFalse : true});
    } else {
      setState({ ...state, Pm : ' ', PmTrueFalse : false });
    }    
  }
  const handleChange = (name: string, value: number | string | Date | null) => {
    let workValue: any = [];
    const wValue: any = value;
  
    setState({ ...state, [name]: value })

    if (name === 'type') {
      workValue = filters.parameters.typesValues.find( (type: any, i: number) => {
        return type[0] == value
      });
      
      //setState({ ...state, subject: workValue[2], location: workValue[3], toimikunta: workValue[4]});
      setState({...state, subject: workValue[2], type: wValue, Location: workValue[3] , Toimikunta: workValue[4]});     
    }
    if (name === 'subject') {
      setState({...state, type : ''});
    }
      
    setState({...state, [name] : wValue});
  };
  const paluu = async () => {
    //dispatch(serviceEdited(true));
    close()
  }

  const submit = async () => {
    const param = [[ state.type,
    state.excluded, 
    state.Vko, 
    state.Vkonpäivä,
    state.StartDate,
    state.StartTime,
    state.EndDate,
    state.EndTime,
    state.Pm, 
    state.subject,
    state.Selite,
    state.Location,
    state.Toimikunta,
    state.Private,
    state.sspeaker,
    state.Seuraisäntä,
    state.Säestäjä,
    state.Esilaulaja,
    state.Valvontaryhmänvetäjä,
    state.Englannintulkkaus,
    state.Eestintulkkaus,
    state.Viittomakielentulkkaus,
    state.Äänentoisto,
    state.Siivousryhmä,
    state.Kassaryhmä,
    state.linenumber,
    'n',
    auth.email,
    ]];
    if (state.subject === '') {
      dispatch(editFilterData({ error: true, errorMessage: 'Anna tilaisuuden nimi' }));
    };
    if (filters.serviceYear  != moment(state.StartDate,'D.M.YYYY').year()) {
      dispatch(editFilterData({ error: true, errorMessage: 'Valitse päivä seuraohjelman vuodelta' }));
    }
    const range = `${filters.currentSheet.subSheet}!${'A'}${state.linenumber}:${'AB'}${state.linenumber+1}`
    const reply = await programService
      .updateLine(token, filters.currentSheet.sheetId, 'xxxx', state.linenumber, state.linenumber, param, range);
    reply.split(' ').map((text:any) => {
      return text === 'Error' && dispatch(editFilterData({error: true, errorMessage: 'Seuraohjelman rivin päivityksessä virhe' }))
    });
    dispatch(startEditServiceProgram(props.serviceId, ({ sspeaker : state.sspeaker, StartDate : state.StartDate, StartTime : state.StartTime ,
      EndDate : state.EndDate, EndTime : state.EndTime, subject : state.subject, Location: state.Location, Pm: state.Pm, Seuraisäntä: state.Seuraisäntä, Säestäjä: state.Säestäjä, Esilaulaja: state.Esilaulaja,  Valvontaryhmänvetäjä: state.Valvontaryhmänvetäjä,
      Englannintulkkaus: state.Englannintulkkaus,
      Eestintulkkaus: state.Eestintulkkaus,
      Viittomakielentulkkaus: state.Viittomakielentulkkaus,
      Äänentoisto: state.Äänentoisto,
      Siivousryhmä: state.Siivousryhmä,
      Kassaryhmä: state.Kassaryhmä,
      linenumber : state.linenumber, Selite: state.Selite  }), filters.serviceYear));
    let calendarDataArray1 = filters.calendarDataArray;
    if (filters.calendarDataArray.length === 0 ) {
      calendarDataArray1 = await submitGetCalendarIdArr(token);

      dispatch( setCalendarIds(calendarDataArray1));
    }
    if (state.originalLocation.trim() !== '' ){
      const calendarId1 = calendarDataArray1.find((cal: any) => cal.calendar === state.originalLocation);
      if (calendarId1) {     
        const service2: any = ({
          StartDate: moment(state.originalStartDate,'D.M.YYYY').format('D-M-YYYY'),
          StartTime: state.originalStartTime,
          EndDate: moment(state.originalEndDate,'D.M.YYYY').format('D-M-YYYY'),
          EndTime : state.originalEndTime,
          Location: state.originalLocation, 
          subject: state.originalSubject, 
        });
        const deleteOnly: string = 'deleteOnly';
        submitGenCalUpd( calendarId1, token, service2, deleteOnly, dispatch, editFilterData);          
      }
    }
    if (state.Location.trim() !== '' ) {
      const calendarId = calendarDataArray1.find((cal: any) => cal.calendar === state.Location);
      const service = ({
        StartDate: moment(state.StartDate,'D.M.YYYY').format('D-M-YYYY'),
        StartTime: state.StartTime,
        EndDate: moment(state.EndDate,'D.M.YYYY').format('D-M-YYYY'),
        EndTime : state.EndTime,
        selite: state.Selite,
        subject: state.Location === 'Keittiö' ? state.Toimikunta + ' / ' + state.subject + ' / ' + state.Selite :state.subject,
        Location: state.Location,
      }) 

      if ( calendarId ) {
        const deleteOnly: string = 'no';
          submitGenCalUpd( calendarId, token, service, deleteOnly,  dispatch, editFilterData);
      }
    }
    
    if (reply === '') {
      close();
    }  
  };

  if (!filters.parameters.typesValues) return (<div><CircularProgress/> loading serviceprograms sub sheet for names and information</div>);
  if (!state) close();

  return( 
    <Box>
      <Box>
        <Typography sx={ subHeaderStyles }>{`Päivitä rivi ${filters.serviceProgramName}`}</Typography>
      </Box>        
      <Box  sx={{ p:1}}>
        <FormControl  sx={{ p:1} }>
          <InputLabel> Seuratilaisuus</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectType"
            value = { state.type.trim() }
            name="type"
            onChange={(e: any) => {
              handleChange('type', e.target.value)
            }}
          >            
            {
            filters.parameters.typesValues.map( (type: any, i: number) =>
              <MenuItem
                key={i}
                value={type[0].trim() }
              >
                { type[1].trim()  }
              </MenuItem>
            )}
          </Select>
        </FormControl >    
        <FormControl  sx={{ p:1}}>
          <InputLabel> Muu tilaisuus</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectSubject"
            value = {state.subject.trim()}
            name="subject"
            onChange={(e: any) => {
              handleChange('subject', e.target.value)
            }}
          >            
            {
            filters.parameters.subjectValues.map( (subj: any, i: number) =>
              <MenuItem
                key={i}
                value={ subj.trim() }
              >
                { subj.trim() }
              </MenuItem>
            )}
          </Select>
        </FormControl>       
        <FormControl  sx={{ p:1} }>
          <InputLabel> Toimikunta</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="Toimikunta"
            value = { state.Toimikunta.trim()}
            name="Toimikunta"
            onChange={(e: any) => {
              handleChange('Toimikunta', e.target.value)
            }}
            >      
            {
            filters.parameters.TMKValues.map( (tmk: any, i: number) =>
              <MenuItem
                key={i}
                value={tmk[0].trim()}
              >
                { tmk[0].trim() }
              </MenuItem>
            )}          
          </Select>
        </FormControl>
        <FormControl  sx={{ pt:1 }}>
          <InputLabel> Location</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="Location"
            value = { state.Location.trim() }
            name="location"
            onChange={(e: any) => {
              handleChange('Location', e.target.value)
            }}
           >      
            {
            filters.parameters.locationValues.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0].trim() }
                >
                { loc[0].trim() }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Seuraisäntä</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectSeuraisäntä"
            value = { state.Seuraisäntä.trim() }
            name="Seuraisäntä"
            onChange={(e: any) => {
              handleChange('Seuraisäntä', e.target.value)
            }}
           >      
            {
            filters.parameters.seuraIsannat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0].trim() }
                >
                { loc[0].trim() }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Säestäjä</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectSaestaja"
            value = { state.Säestäjä.trim() }
            name="Säestäjä"
            onChange={(e: any) => {
              handleChange('Säestäjä', e.target.value.trim())
            }}
           >      
            {
            filters.parameters.saestajat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0].trim()}
                >
                { loc[0].trim() }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Esilaulaja</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectEsilaulaja"
            value = { state.Esilaulaja.trim() }
            name="Esilaulaja"
            onChange={(e: any) => {
              handleChange('Esilaulaja', e.target.value)
            }}
           >      
            {
            filters.parameters.esilaulajat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0].trim()}
                >
                { loc[0].trim() }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Valvoja</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectValvoja"
            value = { state.Valvontaryhmänvetäjä.trim() }
            name="Valvoja"
            onChange={(e: any) => {
              handleChange('Valvontaryhmänvetäjä', e.target.value)
            }}
           >      
            {
            filters.parameters.valvojat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0].trim()}
                >
                { loc[0].trim() }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
     
      <FormControl sx={{ pt:1, pb:1 }}>
      <InputLabel> Äänentoisto</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectÄänentoisto"
            value = {state.Äänentoisto.trim()}
            name= "Äänentoisto"
            onChange={(e: any) => {
              handleChange('Äänentoisto', e.target.value)
            }}
           >      
            {
            filters.parameters.aanitarkkailijat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0].trim()}
                >
                { loc[0].trim() }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Tulkki</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectTulkki"
            value = { state.Englannintulkkaus.trim()}
            name="Tulkki"
            onChange={(e: any) => {
              handleChange('Englannintulkkaus', e.target.value)
            }}
           >      
            {
            filters.parameters.tulkit.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0].trim()}
                >
                { loc[0].trim() }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <Box sx={{ p:1 }}>
        <FormControlLabel
          control={<Switch checked={state.PmTrueFalse} onChange={handleChange1} name="Pm" />}
          label="Päivämieheen"
        />
      </Box>    
     
      <TextField
        sx={{ pt:1 }}
        label={'Päivämieheen'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ state.Pm }
        onChange={(e: any) => handleChange('Pm', e.target.value)}
      />
     
      <TextField
        sx={{ mt: 1 }}
        label={'Selite'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ state.Selite}
        onChange={(e: any) => handleChange('Selite', e.target.value)}
      />
      <Box sx = {{fontSize: 20 }}> 
        
        {state.type.trim() !== ''  ?
          <Box sx={{}}>         
            {'Seuratyyppi'}: {            
            filters.parameters.typesValues[ filters.parameters.typesValues.findIndex((ar:any)=> ar[0] === state.type)][2]}
          </Box>
          :
          <Box sx={{ }}> 
          {'Seuratyyppi'} : 'Muu tilaisuus';
          </Box>
          }
        <Box sx={{ pt: 1 }}>
          {'Päivämieheen'}: {state.Pm}
        
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Location'}: { state.Location === 'x' ? ' ' : state.Location}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Vko'}: { state.Vko}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'VkonPäivä'}: { state.Vkonpäivä}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Alkupvm'}: { state.StartDate}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Klo'}: { state.StartTime}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Päättymispvm'}: { state.EndDate}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Klo'}: { state.EndTime}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Subject'}: { state.subject}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Selite'}: { state.Selite}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Location'}: { state.Location}
        </Box>
      </Box>
     
      <Box>
        <Box sx={{ mt: 1 }}>         
          <Box>
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {submit}>
              {('Submit')}
            </Button>   
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {paluu}>
              {('Paluu')}
            </Button>       
          </Box>          
        </Box>
      </Box>
    </Box>
  </Box>
  )
}

export default UpdateServiceLine;