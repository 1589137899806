/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect }  from 'react';
import {   useDispatch, useSelector  } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { Box, CircularProgress, FormControl, IconButton, TextField, Typography, RadioGroup , Table, TableBody,
  TableCell, TableHead, TableRow, FormControlLabel, Radio} from '@mui/material';
import { colors } from '../../styles/themeStylesSx';
import { VisibilityRounded, DeleteRounded } from '@mui/icons-material';
import moment from 'moment';
import {RootState} from '../../store/ConfigureStore';
import {subHeaderStyles} from '../../styles/themeStylesSx';
import SidePanel from '../sidepanel/SidePanel';
import UpdatePermissions from './UpdatePermissions';
import DeletePermissions from './groups/DeletePermissions';
import SubmitPermissionsOfDrive from './SubmitPermissionsOfDrive';
import { editFilterData } from '../../actions/filters';

type UserStateType = {
  open?: boolean;
  openDelete?: boolean;
  driveId?: any;
  add?: boolean;
  linenumber?: number;
  id?: any;
  permission?: string;
  permissions: any[];
  workPermissions?: any[]
};

const DriveUsers =  () => {
  const dispatch = useDispatch();
  const drivesx = useSelector((state: RootState) => state.drives);
  const filters = useSelector((state: RootState) => state.filters);
  const authorit: any = useSelector((state: RootState) => state.auth);
  const allUsers = useSelector((state: RootState) => state.users);
  const [state, setState] = useState<UserStateType>({ open: false, openDelete: false, permissions:[] });
  const navigate = useNavigate();
  const token: any = `bearer ${authorit.idToken}`;
  let permArray2: any = [];  
  const [loading, setLoading] = useState<any>(false);

  useEffect(()=> {
    if (drivesx.length === 0) {
      navigate("/drive");
      return;
    }

    const readPermissions = async (driveId: any, driveName: any) => {
      const drivesPermissions = await SubmitPermissionsOfDrive(token,dispatch,authorit,driveId, driveName, 'GoogleDrive', allUsers);
      for (const perm of drivesPermissions) {
        permArray2.push(perm);
      }
      setState({...state, permissions: permArray2, workPermissions: permArray2})
      return drivesPermissions;           

    }
   
    const doArray = async () => {
      for (const drive of drivesx) {
        const drivesPermissions: any = await readPermissions( drive.id, drive.driveName);  
      }
      setLoading(false);
     
    }  
    setLoading(true);
    doArray();
  },[filters.somethchanged]);

  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksia drive managing</div>);
  if (loading) return (<div> <CircularProgress/><br />Loading DriveUsers </div>);

  const handleSearchChange = (e: any) =>  {
    const searchText = e.target.value;
    dispatch(editFilterData({text: searchText}))
    const permArray1 = state.permissions.filter((perm: any) => perm.displayName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    setState({...state, workPermissions: permArray1});
  }
 

  const handleChange = (e: any) => {
    const sortWork = state.permissions;
    dispatch(editFilterData({sortBy: e.target.value}))
    if (e.target.value === 'name' ) {
      sortWork.sort(( a: any,b: any ) => {
        var x = a.displayName;
        var y = b.displayName;
        if (x < y) {return -1};
        if (x > y) {return 1}
        else return 0;
      })

    } else if (e.target.value === 'drive' ) {
      sortWork.sort(( a: any,b: any ) => {
        var x = a.driveName;
        var y = b.driveName;
        if (x < y) {return -1};
        if (x > y) {return 1}
        else return 0;
      })
    } else if (e.target.value === 'role' ) {
        sortWork.sort(( a: any,b: any ) => {
        var x = a.translatedPermissionValues;
        var y = b.translatedPermissionValues;
        if (x < y) {return -1};
        if (x > y) {return 1}
        else return 0;
      })
    } else if (e.target.value === 'lastLogin' ) {
      sortWork.sort(( a: any,b: any ) => {
        var x = a.lastLoginDate === '' ? moment('1970-01-01').format('YYYY.MM.DD') : moment(a.lastLoginDate,'D.M.YYYY').format('YYYY.MM.DD');
        var y = a.lastLoginDate === '' ? moment('1970-01-01').format('YYYY.MM.DD') : moment(b.lastLoginDate,'D.M.YYYY').format('YYYY.MM.DD');
        if (x < y) {return -1};
        if (x > y) {return 1}
        else return 0;
      })
    } else {
      sortWork.sort(( a: any,b: any ) => {
        var x = a.displayName;
        var y = b.displayName;
        if (x < y) {return -1};
        if (x > y) {return 1}
        else return 0;
      })
    }
    setState({...state, workPermissions: sortWork})
  };

  return (
    <Box>
      <SidePanel
       open={ state.open === true }
       close={ () => setState({ ...state, open: false })}
      >
        <Box>
          {state.open &&
            <UpdatePermissions driveId= {state.driveId }  permission= {state.permission} permissions= { state.permissions } close ={ () => setState({ ...state, open: false })} />
          }
        </Box> 
      </SidePanel>
      <SidePanel
       open={ state.openDelete === true }
       close={ () => setState({ ...state, open: false, openDelete: false })}
      >
        <Box>
          {state.openDelete &&
          
            <DeletePermissions driveId= {state.driveId } openDelete = {state.openDelete}
            permission= {state.permission} close ={ () => setState({ ...state, open: false, openDelete: false })} />
          }
        </Box>
      </SidePanel>
      <Box>
        <Box>
          <FormControl>
            <Typography sx={ subHeaderStyles }>{'Lajittelu' }</Typography>
            <RadioGroup aria-label="gender" name="gender1" value={ filters.sortBy} onChange={handleChange}>
              <FormControlLabel value="name" control={<Radio />} label="Nimi" />
              <FormControlLabel value="drive" control={<Radio />} label="Drive" />
              <FormControlLabel value="role" control={<Radio />} label="Rooli" />
              <FormControlLabel value="lastLogin" control={<Radio />} label="Viim kirjautuminen" />
            </RadioGroup>
          </FormControl>
          <TextField
            style={{ minWidth: '150px'}}
            label="Rajaus"
            autoFocus
            name="rajaus"
            value={filters.text}
            onChange= {handleSearchChange}
          
          />
        </Box>
        <Box>
          <Box>
            <Typography sx={ subHeaderStyles }>{'Hry:n Henkilöiden Drive-oikeudet' }</Typography>
            <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
              <TableHead>
                <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                  <TableCell>{}</TableCell>
                  <TableCell>{}</TableCell>
                  <TableCell>{'Name'}</TableCell>
                  <TableCell>{'Tyyppi'}</TableCell>
                  <TableCell>{'Drive-Name'} </TableCell>
                  <TableCell>{'Role'} </TableCell>
                  <TableCell>{'Viimeinen käyttöpäivä'} </TableCell>
                  <TableCell>{'Tunnus luotu'} </TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
              {
              state.workPermissions?.map((permission: any, i: any) => {
              const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (
                <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {`${i}, {drive.driveId}_row5`}>
                  <TableCell sx={{
                backgroundColor,
                borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                <IconButton onClick={() => setState({ ...state, open: true, driveId : permission.driveId , permission: permission })}><VisibilityRounded/></IconButton>
              </TableCell>

              { !permission.organizer ?
                <TableCell sx={{
                  backgroundColor,
                  borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                  <IconButton onClick={() => setState({ ...state, open: false, openDelete: true, permission: permission, driveId : permission.driveId })}><DeleteRounded/></IconButton>
                </TableCell>
                :
                <TableCell></TableCell>
              }
                  <TableCell>{permission.displayName}</TableCell>
                  <TableCell>{permission.type}</TableCell>
                  <TableCell>{permission.driveName}</TableCell>
                  <TableCell>{permission.translatedPermissionValues}</TableCell>
                  <TableCell>{permission.lastLoginDate}</TableCell>
                  <TableCell>{permission.creationDate}</TableCell>
                </TableRow>
              )})
              }
            </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default DriveUsers