/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react'
// import { v4 as uuidv4 } from 'uuid';
import { VisibilityRounded } from '@mui/icons-material';
import PickUpDays from './PickUpDays';
import programService from '../../services/programService'
import {  speakersToStateOnce } from '../../actions/speakers'
import { colors } from '../../styles/themeStylesSx';
import {editFilterData, setSheetData, setServiceYearListSpeakers, setLoading} from '../../actions/filters';
import SidePanel from '../sidepanel/SidePanel';
import SpeakerListFilters from './SpeakerListFilters'
import submitGetSheetId from '../service/submitGetSheetId';
import moment from 'moment'
import {   useDispatch, useSelector  } from 'react-redux';
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress  } from '@mui/material';
import {RootState} from '../../store/ConfigureStore';
import { useNavigate } from "react-router-dom";
import createSpeakersState from './createSpeakersState';
import MouseOverPopOver from '../../utilities/MouseOverPopover';


type SpeakerStateType = {
  open?: boolean;
  speakerId?: number;
  add?: boolean;
  speaker?: string;
};


const ListSpeakersPage = (props: any) => {
  const [state, setState] = useState<SpeakerStateType>({ open: false });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ speakers, setSpeakers ] = useState([]);
  const [ loading, toggleLoading ] = useState(false);
  const auth: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters)
  const speakersState = useSelector((state: RootState)=> state.speakers)
  // const [ sloading, stoggleLoading ] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ sheetLoading, stoggleSheetLoading ] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  
  const [anchorE2, setAnchorE2] = React.useState<HTMLElement | null>(null);
  const [anchorE3, setAnchorE3] = React.useState<HTMLElement | null>(null);
  const token = `bearer ${auth.idToken }`
  const dispatch = useDispatch();
  let popOverText = 'Muuta puhujavapaita'

  let componentMounted = true;
  let navigate = useNavigate();

  let speakerState = [];
  speakerState = [];
  // useEffect (() => {   
  //   if (filters.loading) {
  //   <CircularProgress/>
  //   }
    
  // },[]);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorEl(event.currentTarget);
  };
  const handlePopoverOpen1 = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorE2(event.currentTarget);
  };
  const handlePopoverOpen2 = (event: React.MouseEvent<HTMLElement>) => {

    setAnchorE3(event.currentTarget);
  };
  
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handlePopoverClose1 = () => {
    setAnchorE2(null);
  };
  const handlePopoverClose2 = () => {
    setAnchorE3(null);
  };
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (auth.idToken) {
      setState({ open: false })
      //if (auth.idToken ) {

      const retr = async (token: any, year: any) => {
        const doDispatch = true;
        const sheetData: any = await submitGetSheetId(token,'speakers' + year, dispatch, doDispatch, filters );
        if (sheetData.serviceYear !== '' ) {
          dispatch(setSheetData(sheetData));
          const sheetId = sheetData.sheetId;
          const range = sheetData.range;
          let work : number = props.currentYear;
          work = moment().year() + 1;
          let workYears: any = [];
          if (sheetData.sheetYears) {
            workYears = sheetData.sheetYears;
          } else 
          {
            workYears = filters.sheetYears;
          }
          const workTypes = await workYears.find((typeLine: any) => typeLine[0] === 'types' + work);
          await getTypes(token, 'types' + work, workTypes[1], workTypes[2])
          await getSpeakers(token,year,sheetId,range)
          return sheetData;
        }
        else {
          toggleLoading(false);
          dispatch(setLoading(false))
        }
      }


      const getSpeakers = async (token: any, year: string, sheetId: string, range: string) => {
        let initialSpeakers: any = [];
        const initialLines = await programService
          .getAll(token,year,sheetId,range)
          if (Array.isArray(initialLines)) {
            initialSpeakers = initialLines
          }
          else {
            initialSpeakers = [];
            dispatch(editFilterData({error: true, errorMessage: initialLines}));
          }
        setSpeakers(initialSpeakers);
        // let riviCount = 0;
        speakerState = await createSpeakersState(initialSpeakers);

        dispatch (speakersToStateOnce(speakerState));
        if (componentMounted){ // (5) is component still mounted?
          stoggleSheetLoading(false); // (2) write some value to state
          toggleLoading(false);
          dispatch(setLoading(false))
        }
      }
      const getTypes = async (token: any, year: string, sheetId: string, range: string) => {
        let initialTypes: any = [];
        const typeLines = await programService
        .getAll(token, year, sheetId, range  )
          if (Array.isArray(typeLines)) {
            initialTypes = typeLines;
          }
          else {initialTypes = [];
          dispatch(editFilterData({error: true, errorMessage: 'SheetId: Tyypit-sivu puuttuu seuraohjelmasta'}));
          }
        dispatch(editFilterData({iniTypes :initialTypes}));
      }
      let searchYear: any = '';
      if (props.currentYear) {
        dispatch( setServiceYearListSpeakers(moment(props.currentYear,'YYYY').format('YYYY')));
        searchYear = props.currentYear;
      } else 
      {
        dispatch( setServiceYearListSpeakers(moment().year() + 1));
        searchYear = moment().year() + 1;
      }
      dispatch(setLoading(true))
      toggleLoading(true);
      // if (auth.speakerName)
      retr(token, searchYear )

      //}  
      //else navigate("/info");
      return () => { // This code runs when component is unmounted
        componentMounted = false; // (4) set it to false if we leave the page
      }
   }
  
  }, [auth.idToken])
  let falseSpeaker = '';
    // if (initialSheets.sheets[0]) {
  
  const printAllLines = filters.admin && filters.text === '' && !props.speaker ? true : false;

  useEffect (() => {
    if (!auth.idToken) {
      navigate('/');
      return;
    }
    falseSpeaker = auth.speakerName
    if (filters.admin && filters.text !=='' ) {
      falseSpeaker = filters.text
    };
    if (props.speaker) {
      falseSpeaker = props.speaker.speaker;     
    } 
    dispatch(editFilterData({text: falseSpeaker}));
  },[props.speaker, auth.idToken ]);

  if (!auth.idToken) return <div> Kirjaudu järjestelmään</div>
  if (loading)  return <div><CircularProgress /> <br />Loading speakers data</div>
  if (!auth.idToken) return <div> xxxx<CircularProgress/> <br/> höpöhöpö</div>
  if (filters.loading) return <div>yyyy <CircularProgress/></div>


  if (!filters.freetime || !auth.speakerName){
    return (
      <Box>
        <div> <br />. Eiole vaadittuja oikeuksia / Tai et ole kirjautunut </div>
      </Box>
      );
      
  }
  return (
    <Box>      
      <Box>
        {(filters.iniTypes.length > 0 ) &&
          <Box>
            <SidePanel
              open={ state.open === true }
              close={ () => setState({ open: false })}
            >
              <PickUpDays speaker ={ speakersState.find((row: any) => row.linenumber === state.speakerId )} close ={ () => setState({ open: false })} />
            </SidePanel>
            <SpeakerListFilters speaker = {filters.text} />
            <Table sx={{ display: { xs: 'inline-table', md: 'inline-table', color: 'secondary.main' }}}>
              <TableHead>
                <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                  <TableCell
                  >
                    
                  </TableCell>
                  <TableCell>{'Puhuja'}</TableCell>
                  <TableCell
                    aria-owns = { open ? 'mouse-over-popover' : undefined}
                    aria-haspopup = "true"
                    onMouseEnter={handlePopoverOpen1}
                    onMouseLeave={handlePopoverClose1}
                  >{'Poissuljetut'}</TableCell>
                  <MouseOverPopOver popOverText = { 'Näihin tapahtumatyyppeihin ei palveluvuoroja' } anchor = { anchorE2 }/>
                  <TableCell
                  aria-owns = { open ? 'mouse-over-popover' : undefined}
                  aria-haspopup = "true"
                  onMouseEnter={handlePopoverOpen2}
                  onMouseLeave={handlePopoverClose2}
                  >{'Vapaa-ajat'}</TableCell>
                  
                  <MouseOverPopOver popOverText = { 'Näihin aikoihin, ajanjaksoihin  ei palveluvuoroja' } anchor = { anchorE3 }/>
                </TableRow>
              </TableHead>
            { (printAllLines) ?
              <TableBody>
                {
                  speakersState.map((spe: any, i: any) => {                  
                    const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                    return (                    
                      <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${spe.linenumber}_row`}>
                        <TableCell sx={{
                          backgroundColor,
                          borderTopRightRadius: 15, borderBottomRightRadius: 15}}>
                            <IconButton sx={{p: 0}} onClick={() => setState({ open: true, speakerId: spe.linenumber })}><VisibilityRounded /></IconButton>
                        </TableCell>
                        <TableCell sx={{
                          backgroundColor,
                          borderTopLeftRadius: 15, borderBottomLeftRadius: 15,
                          }}>{ spe.speaker }</TableCell>
                          
                        <TableCell sx={{backgroundColor}}>{ spe.typesArray.map((type: any) => filters.iniTypes[filters.iniTypes.findIndex ((line: any) => line[0] === type)][1] + ', ') }</TableCell> 
                        <TableCell sx= {{backgroundColor}}>{  spe.datesArrayFinal.filter((fil: any) =>
                          moment(fil.apvm).isBefore('2040-12-31')).map((date: any) => moment(date.apvm).format('D.M.YYYY') +'-'+ moment(date.ppvm).format('D.M.YYYY') +' ') }</TableCell>
                    
                      </TableRow>
                    );
                  })
                  
                }
              </TableBody>            
              :
              <TableBody>
                {
                speakersState.filter((spe: any) => spe.speaker.toLowerCase().includes(filters.text.toLowerCase()))
                  .map((spe: any, i: any) => {
                  const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                  return (
                    <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${spe.linenumber}_row`}>
                      <TableCell                     
                        aria-owns = { open ? 'mouse-over-popover' : undefined}
                        aria-haspopup = "true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}     
                        sx={{
                          backgroundColor,
                          borderTopRightRadius: 15, borderBottomRightRadius: 15}}>
                          <IconButton sx={{p: 0}} onClick={() => setState({ open: true, speakerId: spe.linenumber })}><VisibilityRounded /></IconButton>                     
                      </TableCell>
                      <MouseOverPopOver popOverText = { 'Avaa' } anchor = { anchorEl }/>
                      <TableCell sx={{
                        backgroundColor,
                        borderTopLeftRadius: 15, borderBottomLeftRadius: 15,
                        }}>{ spe.speaker }</TableCell>
                      <TableCell sx={{backgroundColor}}
                    
                    
                      >{ spe.typesArray.map((type: any) => filters.iniTypes[filters.iniTypes.findIndex ((line: any) => line[0] === type)][1] + ', ' ) } </TableCell>
                      <TableCell sx= {{backgroundColor}}>{  spe.datesArrayFinal.filter((fil: any) =>
                      moment(fil.apvm).isBefore('2040-12-31')).map((date: any) => moment(date.apvm).format('D.M.YYYY') +'-'+ moment(date.ppvm).format('D.M.YYYY') +' ') }</TableCell>            
                    </TableRow>
                    
                  );
                })
                }
              </TableBody>
            }
          </Table>
        </Box>
      }
      </Box>
    </Box>

  )
}
export default ListSpeakersPage;
