/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import moment from 'moment';
import userServices from '../../services/userServices'
import { VisibilityRounded } from '@mui/icons-material';
import { editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import { Box, Button, IconButton, Snackbar, Table, TableBody, TableCell, TableRow, TableHead, Typography, CircularProgress } from '@mui/material';
import { colors } from '../../styles/themeStylesSx';
import {AccountLineType} from '../../types/accountLine';
import SidePanel from '../sidepanel/SidePanel';
import SidePanelLarge from '../sidepanel/SidePanelLarge';
import ListGroupMembersSimple from '../drives/groups/ListGroupMembersSimple';
import AddUserToSeveralGroups from './AddUserToSeveralGroups';

type QueryGroupsType = {
  close: () => void,
  primaryEmail: string;
};

const QueryGroupsSimple = (props: QueryGroupsType) => {
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  const auth: any = useSelector((state: RootState) => state.auth);
  const allGroups: any = useSelector((state: RootState) => state.groups);
  const [ loading, toggleLoading ] = useState(false);
  const token = `bearer ${auth.idToken}`;
  const [inclGroups, setInclGroups] = useState< []>([])
  const [notIncludedGroups, setNotIncludedGroups ]= useState<any>([]);
  const close = props.close
  const [state, setState] = useState<AccountLineType>({
    creationDate: moment().format('D.M.YYYY'),
    lastLoginDate:'',
    id:'',
    groups: [],
    userEmail:'',
    primaryEmail: '',
    addUser: false,
    deleteUser: false,
    openDrive: false,
    openGroup: false,
    familyName: '',
    givenName: '',
    passWord: '',
    customerId: '',
   //selectGroups: false,
    
  });

  useEffect (() => {
    const readGroups = async (primaryEmail: any) => {
      try {
        const groups: any | [] = await userServices
        .inWhichGroups(token, primaryEmail)
        setInclGroups(groups.data); 
        toggleLoading(false);    
        let workGroups = []
        for (const gro of allGroups ) {
          const found = groups.data.findIndex((gg: any) => { 
            return  gro.email === gg.email});
          if (found === -1 ) {
            workGroups.push(gro);
          }
        }
        setNotIncludedGroups(workGroups);
        dispatch(editFilterData({userEmails: groups.data}))
      }catch(error) {
        console.error('Error fetching inwhichgroups querygroupssimple', error)
      }
   
      toggleLoading(false);
    }      
  toggleLoading(true);
  readGroups(props.primaryEmail);   
  },[props.primaryEmail]);  

  useEffect (() => {
    let workGroups = []
    for (const gro of allGroups ) {
      const found = filters.userEmails.findIndex((gg: any) => { 
        return  gro.email === gg.email});
      if (found === -1 ) {
        workGroups.push(gro);
      }
    }
    setNotIncludedGroups(filters.userEmails)
    setNotIncludedGroups(workGroups);

  }, [filters.somethchanged])
    
  if (loading ) return <div><CircularProgress /> <br />loading...</div>
  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
  };
  // if (inclGroups) <CircularProgress/>

  const paluu = async () => { 
    close()

  }
  // const submit = async (e: any) => {
  //   e.preventDefault()
  //   setState({...state, selectGroups: true });
  // }; 

  return(
 
    <Box>
      { filters.error &&
        <div>
          <Snackbar
            open={filters.error}
            autoHideDuration={10000}
            onClose={handleClose}
            message= {filters.errorMessage}
          />
        </div>
      }
      <SidePanel
        open={ state.openGroup === true }
        close={ () => setState({ openGroup: false })}
      >
        <Box>
          {state.openGroup &&
            <ListGroupMembersSimple group= {allGroups.find((gro: any) => state.group?.email === gro.email )} close ={ () => setState({ openGroup: false })} />
          }
        </Box>

      </SidePanel>

      <SidePanelLarge
        open={ state.addUser === true }
        close={ () => setState({ openGroup: false })}
      >
        <Box>
          {state.addUser &&
            <AddUserToSeveralGroups groups= { notIncludedGroups } userEmail = { props.primaryEmail } close ={ () => setState({ openGroup: false })} />
          }
        </Box>

      </SidePanelLarge>

      <Box>
        <Typography sx={ subHeaderStyles }>{`${props.primaryEmail} on seuraavissa ryhmissä`}</Typography>
      </Box>  
      <Box>
        <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
              <TableCell></TableCell> 
              <TableCell>{'Email'}</TableCell>      
              <TableCell>{'Nimi'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
            {inclGroups.length > 0 &&           
              inclGroups.map((user: any, i: number) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return (
                  <TableRow  sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${user.id}_row20`}>
                    <TableCell>
                      <IconButton  onClick={() => setState({...state, openGroup: true, group: user })}><VisibilityRounded /></IconButton>                    
                    </TableCell> 
                    <TableCell sx={{backgroundColor }}>{ user.email }</TableCell>                  
                    <TableCell sx={{backgroundColor }}>{ user.name }</TableCell>
                  </TableRow>
                ) 
              })
              }
            
          </TableBody>  
        </Table>   
         <Box sx={{ mt: 1 }}>         
          <Box sx={{p:1}}>
            <Typography sx={ subHeaderStyles }>{`Lisää henkilö Lisää-painonapilla ryhmiin`}</Typography>
              <Button sx= {{p: 2, size:"small" }  } variant='contained' color="primary"
                onClick={() => setState({ ...state, addUser: true, groups: notIncludedGroups, primaryEmail:  state.primaryEmail } )}>Lisää</Button>

            <Button color="primary" variant="contained" sx={{ml:1, p: 2, size: "small"}}
              onClick= {paluu}>
              {('Paluu  ')}
            </Button>       
          </Box>          
        </Box>  
      </Box>
    </Box>
  )
}

export default QueryGroupsSimple;