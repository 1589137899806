import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconButton, CircularProgress,  Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Button, Tooltip } from '@mui/material';
import { editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import SidePanel from '../sidepanel/SidePanel';
import { colors } from '../../styles/themeStylesSx';
import { DeleteRounded } from '@mui/icons-material';
import moment from 'moment';
import DeleteUser from './DeleteUser';
import userServices from '../../services/userServices';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import { initialDb, retrieveGroupsDb } from '../../services/sqlLiteServices';
import groupServices from '../../services/groupServices';
import { addGroups } from '../../actions/groups';
import submitDrivesToState from '../drives/submitDrivesToState';
import driveServices from '../../services/driveServices';
import { retrievePermissions } from '../../services/sqlLiteServices';
let popOverText = '';

export default function  UserNoRoleComponent()  {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  let users: any = useSelector((state: RootState) => state.users);
  const auth: any = useSelector((state: RootState) => state.auth);
  const drives = useSelector((state: RootState) => state.drives);
  const token: any = `bearer ${auth.idToken}`;
  const [loading, toggleLoading] = useState(false);
  const [state, setState] = useState<any>({
    kind: '',
    etag: '',
    id: 0,
    email:  '',
    role: '',
    type: 'any',
    status: '',
    käyttäjä: '',
    openAdd: false,
    userId: 0,
    groupId: '',
    editLine: false,
    deleteUSer: false,
    openDelete: false,
    noRole: [],
    permissionsAll: []
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    popOverText =`Näyttää tilin drive-oikeudet tai ryhmät`;
  };
  const handlePopoverOpen1 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    popOverText = 'Rajaa haun tulokset tekstin mukaan. Listan lopussa LISÄÄ TILI - painonappi'
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  
  useEffect(() => {
    if (!auth.idToken) {
      navigate('/');
      return;
    }
    const callDrive = async () =>  {
      const permissionArray = await retrievePermissions(token);
      setState({...state, permissionsAll: permissionArray});
      toggleLoading(false);
    }
    toggleLoading(true)
    callDrive();
   
  }, [auth.idToken]) 
  
  let workUsers: any = users;
  if (!filters.admin) return <div> EI  oikeuksia</div>
  if (loading) return <div> <CircularProgress /> Tämä raportti kestää pitkäääään. Hae kahvit ....</div>

  const readGr = async () => {
    try {
      await initialDb(token);
    }catch(error){
      console.error('error in initializing MemoryDb', error)
      dispatch(editFilterData({error: true, errorMessage: 'Memorydb kanna initialisointivirhe submitGroupsToState'}))
    }
    try{
      const response = await groupServices
    .groupList(token, auth.uid )
    if (response === 'Timeout reached') {
      dispatch(editFilterData({error: true, errorMessage: 'Palvelin teki aikakatkaisun. Ole hyvä ja tee kysely uudestaan. Kyllä palvelin josssain kohtaa taas jaksaa loppuun asti', stopOutput: true }))
      return
    }
    }catch(error) {
      console.error('Error fetching grouplist', error)
      dispatch(editFilterData({error: true, errorMessage: 'virhe ryhmien haussa'})) ;
    }
  }
  
  const readUnUsed = async () => {
    try {
      // Step 1: Retrieve data from the memory database
      //const response: any = await retrieveGroupsDb(token);
      const response = await retrieveGroupsDb(token)
      dispatch(addGroups(response));
    } catch (error: any) {
      console.error('Error in getRows from MemoryDb', error?.response?.status);
      
      if (error?.response?.status === 409) {
        dispatch(
          editFilterData({
            error: true,
            errorMessage:
              'SQLite service down. Fetching group data from Google Cloud. Grab a coffee, this might take a while...',
          })
        );
        await readGr();
      } else {
        dispatch(
          editFilterData({
            error: true,
            errorMessage: 'Error reading from MemoryDB in submitGroupsToState',
          })
        );
      }
    }
  
    try {
      // Step 2: Fetch users with no roles
      const noRoleAccounts = await userServices.noRoleList(token);
  
      // Step 3: Filter users not present in permissionsAll
      const noRoleAccountsFromDrives = noRoleAccounts.filter((acc: any) => {
        const isUnused = !state.permissionsAll.some(
          (perm: any) => perm.emailAddress === acc.primaryEmail
        );
        return isUnused;
      });
  
      // Step 4: Update state with filtered accounts
      setState({ ...state, noRole: noRoleAccountsFromDrives });
    } catch (error) {
      console.error('Error in UserNoRoleComponent', error);
      dispatch(
        editFilterData({
          error: true,
          errorMessage:
            'Something went wrong while listing unused users.',
        })
      );
    } finally {
      toggleLoading(false);
    }
  };
  
  const  workArray = workUsers.filter((perm: any) => perm.primaryEmail.toLowerCase().indexOf(filters.searchText.toLowerCase()) !== -1);
  workUsers = workArray;

  const start = async() => {
    toggleLoading(true)
    await readUnUsed();
    if (loading) return <div> <CircularProgress /> Tämä raportti kestää pitkäääään. Hae kahvit ....</div>
     toggleLoading(false)
    
  }
  const paluu = async () => { 
    navigate("/info");

  }

  return (
    <Box>

      <SidePanel
      open={ state.deleteUser === true }
      close={ () => setState({...state, deleteUser: false })}
      >
      {state.deleteUser && 
      <DeleteUser primaryEmail = {state.primaryEmail} users = {users} openDelete = {true} close = { () => setState({...state,deleteUser: false})} />
     }
      </SidePanel> 
      <Typography sx={ subHeaderStyles }>{`Etsitään tilit, joita ei ryhmissä ja joilla ei drive-oikeuksia`}</Typography>
   
      <Box>
        <Box>
          <Button color="primary" variant="contained" sx={{ ml: 1 }}
            onClick= {start}>
            {('Aloita')}
          </Button>       
        </Box>          
        <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
          <TableHead>
            <Tooltip title="Käyttäjät, jotka eivät missään ryhmässä eikä drive-kansion käyttäjänä" arrow>
              <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                <TableCell></TableCell> 
                <TableCell>{'Email'}</TableCell>      
                <TableCell>{'Luotu'}</TableCell>
                <TableCell>{'Viimeinen käyttö'}</TableCell>
              </TableRow>
            </Tooltip>
          </TableHead>
          <TableBody>             
          { state.noRole.map((user: any, i: number) => {
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
            return (            
            <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${user.id}_row21`}>
              <TableCell
                aria-owns = { open ? 'mouse-over-popover1' : undefined}
                aria-haspopup = "true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                >
          
                <IconButton onClick={() => setState({ ...state, openDrives: false, openGroups: false, deleteUser: true, primaryEmail: user.primaryEmail })}><DeleteRounded/></IconButton>
            
              </TableCell>  
              <TableCell sx={{backgroundColor }}>{ user.primaryEmail }</TableCell>
              <TableCell sx={{backgroundColor }}>{ moment(user.creationTime).format('D.M.YYYY') }</TableCell>
              <TableCell sx={{backgroundColor}}>{ moment(user.lastLoginTime).format('D.M.YYYY') }</TableCell>
            </TableRow>
            )
          })}        
         </TableBody>
        </Table>
        <Box sx={{ mt: 1 }}>         
          <Box>
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {paluu}>
              {('Paluu')}
            </Button>       
          </Box>          
        </Box>
    </Box>
  </Box>
  )
}
