// import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { Box, Button, Grid, TextField, CircularProgress } from '@mui/material';
// import isEmail from 'email-validator';
// import TransferUsers from '../TransferUsers';
// import submitListMembersOfGroup from '../submitListMembersOfGroup';
// import { editFilterData } from '../../../actions/filters';
// import { addMembersToGroup } from '../../../actions/groups';
// import { RootState } from '../../../store/ConfigureStore';

// interface AddUserProps {
//   member?: any;
//   group?: any;
//   open?: boolean;
//   openDelete: any;
//   members?: any;
//   close: () => void;
// }

// const AddUserToGroup: React.FC<AddUserProps> = ({ group, close }) => {
//   const dispatch = useDispatch();
//   const { users, groups, filters } = useSelector((state: RootState) => ({
//     users: state.users,
//     groups: state.groups,
//     filters: state.filters,
//   }));
//   const auth: any = useSelector((state: RootState) => state.auth);
//   const [loading, setLoading] = useState(false);
//   const [searchResults, setSearchResults] = useState<any[]>([]);
//   const [civilEmails, setCivilEmails] = useState<any[]>([]);
//   const [members, setMembers] = useState<string[]>([]);
//   const [selectedGroupMembers, setSelectedGroupMembers] = useState<any[]>([]);
//   const [email, setEmail] = useState('');
//   const token = `bearer ${auth.idToken}`;
  
//   useEffect(() => {
//     const fetchMembers = async () => {
//       if (!group) {
//         dispatch(editFilterData({ error: true, errorMessage: 'Tarkista AddUserToGroup parametrit' }));
//         return;
//       }
//       setLoading(true);

//       const selectedGroup = groups.find((g: any) => g.id === group.id);
//       if (selectedGroup.members) {
//         setSelectedGroupMembers(selectedGroup.members);
//         setMembers(selectedGroup.members.map((m: any) => m.email));
//       } else {
//         try {
//           const memberList = await submitListMembersOfGroup(token, group.id);
//           dispatch(addMembersToGroup(group.id, memberList));
//         } catch (error) {
//           console.error('Error fetching members:', error);
//           dispatch(editFilterData({ error: true, errorMessage: 'Tarkista ohjelman kutsuparametrit' }));
//         }
//       }
//       setLoading(false);
//     };
//     fetchMembers();
//   }, [group]);

//   if (loading) return <div>Ladataan tietoja... <CircularProgress /></div>;

//   const alreadyInGroup = (email: string) => selectedGroupMembers.some((m: any) => m.email === email);

//   const handleSearchChange = (value: string) => {
//     const filteredUsers = users.filter((user: any) =>
//       user.primaryEmail.toLowerCase().includes(value.toLowerCase()) && !alreadyInGroup(user.primaryEmail)
//     );
//     setSearchResults(filteredUsers);
//   };

//   const handleAddCivilEmail = () => {
//     if (civilEmails.some((c) => c.primaryEmail === email)) {
//       dispatch(editFilterData({ error: true, errorMessage: 'Käyttäjätunnus on jo listalla' }));
//       return;
//     }

//     if (!isEmail.validate(email)) {
//       dispatch(editFilterData({ error: true, errorMessage: 'Virheellinen sähköposti' }));
//       return;
//     }
// console.log('siviilit', civilEmails, '--',searchResults)
//     setCivilEmails([...civilEmails, { primaryEmail: email.toLowerCase() }]);
//     setEmail('');
//   };

//   const clearSearch = () => {
//     setSearchResults([]);
//     setCivilEmails([]);
//   };

//   return (
//     <Box>
//       <TextField
//         sx={{ ml: 1, p: 1 }}
//         label='Kirjoita lisättävän käyttäjän nimeä'
//         variant='outlined'
//         fullWidth
//         onChange={(e) => handleSearchChange(e.target.value)}
//       />

//       {group && !group.email.includes('drive') && (
//         <Box>
//           <TextField
//             sx={{ ml: 1, p: 1, width: 250 }}
//             label='Anna henkilön siviilisähköposti'
//             variant='outlined'
//             fullWidth
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//           <Button variant='contained' color='primary' sx={{ m: 2 }} onClick={handleAddCivilEmail}>
//             Lisää email
//           </Button>
//         </Box>
//       )}

//       <Button variant='contained' color='primary' sx={{ m: 2 }} onClick={clearSearch}>
//         Tyhjennä haut
//       </Button>
//       <Grid item xs={2}>{TransferUsers([...searchResults, ...civilEmails], setMembers, members, group?.id, selectedGroupMembers, close)}</Grid>
//     </Box>
//   );
// };

// export default AddUserToGroup;
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Grid, TextField, CircularProgress } from '@mui/material';
import isEmail from 'email-validator';
import TransferUsers from '../TransferUsers';
import submitListMembersOfGroup from '../submitListMembersOfGroup';
import { editFilterData } from '../../../actions/filters';
import { addMembersToGroup } from '../../../actions/groups';
import { RootState } from '../../../store/ConfigureStore';

interface AddUserProps {
  group?: any;
  close: () => void;
  openDelete: any;
}

const AddUserToGroup: React.FC<AddUserProps> = ({ group, close }) => {
  const dispatch = useDispatch();
  const { users, groups } = useSelector((state: RootState) => ({
    users: state.users,
    groups: state.groups,
  }));
  const auth: any = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [civilEmails, setCivilEmails] = useState<any[]>([]);
  const [members, setMembers] = useState<string[]>([]);
  const [selectedGroupMembers, setSelectedGroupMembers] = useState<any[]>([]);
  const [email, setEmail] = useState('');
  const token = `bearer ${auth.idToken}`;

  useEffect(() => {
    if (!group) {
      dispatch(editFilterData({ error: true, errorMessage: 'Tarkista AddUserToGroup parametrit' }));
      return;
    }
    const fetchMembers = async (id:any) => {

      const selectedGroup = groups.find((g: any) => g.id === id);

      if (selectedGroup?.members?.length) {
        setSelectedGroupMembers(selectedGroup.members);
        //setMembers(selectedGroup.members.map((m: any) => m.email));
      } else {
        try {
          const memberList = await submitListMembersOfGroup(token, group.id);
          console.log('?????????????is this the problem tsek AddUserToGroup', memberList);
          //must be added selectedGroup- state here?????
          dispatch(addMembersToGroup(group.id, memberList));
        } catch (error) {
          console.error('Error fetching members:', error);
          dispatch(editFilterData({ error: true, errorMessage: 'Virhe ladattaessa ryhmän jäseniä' }));
        }
      }
      setLoading(false);
    };
    setLoading(true);
    fetchMembers(group.id);
  }, [group.id]);

  const alreadyInGroup = useCallback(
    (email: string) => selectedGroupMembers.some((m: any) => m.email === email),
    [selectedGroupMembers]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      const filteredUsers = users.filter(
        (user: any) =>
          user.primaryEmail.toLowerCase().includes(value.toLowerCase()) && !alreadyInGroup(user.primaryEmail)
      );
      setSearchResults(filteredUsers);
    },
    [users, alreadyInGroup]
  );

  const handleAddCivilEmail = () => {
    console.log('eee',email)
    if (!isEmail.validate(email)) {
      dispatch(editFilterData({ error: true, errorMessage: 'Virheellinen sähköposti' }));
      return;
    }
    if (civilEmails.some((c) => c.primaryEmail === email)) {
      dispatch(editFilterData({ error: true, errorMessage: 'Käyttäjätunnus on jo listalla' }));
    }
    else {
      const work = [{ primaryEmail: email.toLowerCase() }];
      const array: any = [...searchResults, ...work];
      setSearchResults(array);;
    }

 

    setCivilEmails([...civilEmails, { primaryEmail: email.toLowerCase() }]);
    setEmail('');
  };

  const clearSearch = () => {
    setSearchResults([]);
    setCivilEmails([]);
    
  };

  if (loading) return <div>Ladataan tietoja... <CircularProgress /></div>;

  return (
    <Box>
      <TextField
        sx={{ ml: 1, p: 1 }}
        label='Kirjoita lisättävän käyttäjän nimeä'
        variant='outlined'
        fullWidth
        onChange={(e) => handleSearchChange(e.target.value)}
      />

      {group && !group.email.includes('drive') && (
        <Box>
          <TextField
            sx={{ ml: 1, p: 1, width: 250 }}
            label='Anna henkilön siviilisähköposti'
            variant='outlined'
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button variant='contained' color='primary' sx={{ m: 2 }} onClick={handleAddCivilEmail}>
            Lisää email
          </Button>
        </Box>
      )}

      <Button variant='contained' color='primary' sx={{ m: 2 }} onClick={clearSearch}>
        Tyhjennä haut
      </Button>
      <Grid item xs={2}>
        {TransferUsers(searchResults, group?.id, selectedGroupMembers, close)}
      </Grid>
    </Box>
  );
};

export default AddUserToGroup;
