/* eslint-disable semi */
/* eslint-disable linebreak-style */
import driveServices from '../../services/driveServices';
import {editPermission} from '../../actions/drives'
import database from '../../firebase/firebase';
import { editFilterData } from '../../actions/filters';

const SubmitPermissionUpdateOfDrive = async (
      token: any,
      driveId: any,
      permission: any,
      uid: any,
      role: any,
      dispatch: any) => {
  const workDriveId = driveId;
  const translatedPermissionValues = ['Sisällönhallinta','Ylläpitäjä','Katsoja', 'Tekijä'];
  const translatedPermissionValue = translatedPermissionValues[['fileOrganizer','organizer','reader','writer','5','6','7','8'].indexOf(role)];
  try {
     await driveServices
    .permissionupdate(token, workDriveId, permission.id,role);
    permission.role= role
    permission.permissionRole = role
    permission.translatedPermissionValues = translatedPermissionValue
    const driveId = permission.driveId;
    dispatch(editPermission({driveId, permission }))
    
  }
  catch(error) {    
    console.log('*********************************************************************************')
    console.log('Error fetching permissions', error)
    dispatch(editFilterData({error: true, errorMessage: 'Permissionin päivityksessä virhe'})); 
  }
  try {
    await database.ref(`users/${uid}/driveList/${permission.drivesId}/permissions/${permission.id}/role`).set(role);
    await database.ref(`users/${uid}/driveList/${permission.drivesId}/permissions/${permission.id}/permissionRole`).set(role);
    await database.ref(`users/${uid}/driveList/${permission.drivesId}/permissions/${permission.id}/translatedPermissionValues`).set(translatedPermissionValue);
  }
  catch(error) {
    console.log('Did not update data,',error);
    dispatch(editFilterData({error: true, errorMessage: 'Permissionin päivityksessä virhe'}));
  }
}
export default SubmitPermissionUpdateOfDrive