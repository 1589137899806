import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const createConfig = (token: string): AxiosRequestConfig => ({
  headers: { Authorization: token },
});

const handleApiError = (error: AxiosError): string => {
  if (error.response) {
    console.error('API Error Response:', error.response);
    return error.response.data?.message || 'Unknown server error';
  } else if (error.request) {
    console.error('API Error Request:', error.request);
    return 'Network error: No response received';
  } else {
    console.error('API Error:', error.message);
    return 'Error setting up the request';
  }
};

const fetchData = async <T>(url: string, config: AxiosRequestConfig): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axios.get(url, config);
    return response.data;
  } catch (error: any) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};
export const loadGroupsDb = async (token: string) => {
  const config = createConfig(token);
  try {
    return await fetchData(`${API_URL}/api/loadGroupsDb`, config);
  } catch (error: any) {
    return handleApiError(error);
  }
};

export const loadDrivesDb = async (token: string) => {
  const config = createConfig(token);
  try {
    return await fetchData(`${API_URL}/api/loadDrivesDb`, config);
  } catch (error: any) {
    return handleApiError(error);
  }
};
export const loadPermissionsDb = async (token: string) => {
  const config = createConfig(token);
  try {
    return await fetchData(`${API_URL}/api/loadPermissions`, config);
  } catch (error: any) {
    return handleApiError(error);
  }
};

export const retrieveGroupsDb = async (token: string) => {
  const config = createConfig(token);
  try {
    return await fetchData(`${API_URL}/api/retrieveGroups`, config);
  } catch (error: any) {
    handleApiError(error);
    throw error;
  }
};

export const initialDb = async (token: string) => {
  const config = createConfig(token);
  try {
    return await fetchData(`${API_URL}/api/initialDb`, config);
  } catch (error: any) {
    return handleApiError(error);
  }
};

export const retrieveDrives = async (token: string) => {
  const config = createConfig(token);
  try {
    return await fetchData(`${API_URL}/api/retrieveDrives`, config);
  } catch (error: any) {
    return handleApiError(error);
  }
};

export const retrievePermissions = async (token: string) => {
  const config = createConfig(token);
  try {
    return await fetchData(`${API_URL}/api/retrievePermissions`, config);
  } catch (error: any) {
    return handleApiError(error);
  }
};
export const deleteAllPermissions = async (token: string) => {
  const config = createConfig(token);
  try {
    return await fetchData(`${API_URL}/api/deleteAllRowsFromPermissions`, config);
  } catch (error: any) {
    return handleApiError(error);
  }
};

export const listPermissionOfDrive = async (token: string, driveId: string) => {
  const config = createConfig(token);
  try {
    return await fetchData(`${API_URL}/api/retrievePermissionOfDrive/${driveId}`, config);
  } catch (error: any) {
    return handleApiError(error);
  }
};

