/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch  } from 'react-redux';
import { CircularProgress, Box, Button, IconButton, Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { RootState } from '../../../store/ConfigureStore';
import { subHeaderStyles } from '../../../styles/themeStylesSx';
import { colors } from '../../../styles/themeStylesSx';
import UpdateRole from '../UpdateRole';
import SidePanel from '../../sidepanel/SidePanel';
import SidePanelMedium from '../../sidepanel/SidePanelMedium';
import { VisibilityRounded, DeleteRounded } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteGroupMember from './DeleteGroupMember';
import submitUsersToState from './../submitUsersToState';
import {  setLoading } from '../../../actions/filters'
import AddUserToGroup from './AddUserToGroup';

type ListMembersType = {
  group: any,
  open?: any,
  close: () => void,
};
type GroupStateType = {
  open?: any;
  member?: any;
  group?: any;
  openDelete? : any;
  members? : any,
  addUser? : any;
  changeRole?: boolean;
  id?: any;
  groupName?: any;
  groupId?: any;
  name?: any;
};

const ListGroupMembers =  (props: ListMembersType ) => {
  const groups: any = useSelector((state:RootState) => state.groups);
  const authorit: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const groupActuel = props.group;
  const [ loading, toggleLoading ] = useState(false);
  const [members,setMembers] = useState<any>([]);
  const [state, setState] = useState<GroupStateType>({ open: false, openDelete: false, addUser: false, members: [], member: '', group: '', changeRole: false });
  const dispatch = useDispatch();
  const token: any = `bearer ${authorit.idToken}`;  

   useEffect(() => {    
    if (groupActuel.members) {
      const membersFunc = async (token: any, groupId: any ) =>  {
        await submitUsersToState(token, dispatch);
        const selectedGroup =groups.find((gro: any ) => gro.id === groupActuel.id);
        const work = selectedGroup.members.sort((a: any, b: any) => {
          var x = a.email
          var y = b.email
          if (x<y) {return -1}
          if (x > y) {return +1}
          if (x === y) {return 1}
        })

        setMembers( work);
        toggleLoading(false);
      }
      toggleLoading(true);
  
      membersFunc(token,groupActuel.id);
    }
    else {
      toggleLoading(false)
      dispatch(setLoading(false));
    }
  }, [filters.somethchanged]);
  
  if (loading) return (<div><CircularProgress/>..members loadingggg</div>)
  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksia</div>)

  return (
    <Box>
      <Box>
        <SidePanel
        open={ state.open === true }
        close={ () => setState({ open: false })}
        >
          <Box>
            { !state.addUser &&
              <DeleteGroupMember member = {state.member} group = {state.group }  open = {state.open} openDelete = {state.openDelete} close ={ () => setState({ open: false })} />
            }
          </Box>
        </SidePanel>
        <SidePanel
        open = { state.openDelete === true }
        close={ () => setState({ open: false })}
        >
          <Box>
            { !state.addUser && 
            <DeleteGroupMember member = {state.member} group = {state.group } members = {state.members} openDelete = {state.openDelete} close ={ () => setState({ open: false })} />
            }
          </Box>
        </SidePanel>
        <SidePanelMedium
        open = { state.addUser === true }
        close={ () => setState({ addUser: false })}
        >

          <Box>
            {state.addUser &&
               <AddUserToGroup group = {state.group } openDelete= {false}  close ={ () => setState({ addUser: false })} />
            }
           
          </Box>
        </SidePanelMedium>
        <SidePanelMedium
        open = { state.changeRole === true }
        close={ () => setState({ changeRole: false })}
        >
          <Box>
            {state.changeRole &&
              <UpdateRole groupId = {groupActuel.id } member= {groupActuel.member} 
              memberId= {state.id} members= {members} name= {groupActuel.name} 
              memberName= {state.name}
              groupName= {state.groupName}
              close ={ () => setState({ changeRole: false })} />
            }
            </Box>
        </SidePanelMedium>
      </Box>
     
      {groupActuel && groupActuel.name &&
       <Typography sx={ subHeaderStyles }>{groupActuel.name}</Typography>
      }
      <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            <TableCell>{'Poista'}</TableCell>
            <TableCell>{'Missä'}</TableCell>
            <TableCell>{'Rooli'} </TableCell>
            <TableCell>{'Email'}</TableCell>
            <TableCell>{'Role'}</TableCell>
            <TableCell>{'Type'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member: any , i: any)=> {
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
            return (
              <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${member.id}_row8`}>
                <TableCell sx={{
                  backgroundColor,
                  borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                  <IconButton  onClick={() => setState({ open: false, openDelete: true, addUser: false, members: members, member: member, group: groupActuel   })}><DeleteRounded /></IconButton>
                </TableCell>
                <TableCell sx={{
                  backgroundColor,
                  borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                  <IconButton  onClick={() => setState({ open: true, openDelete: false,  addUser: false, member: member, group: groups.find((gr: any) => gr.id === groupActuel.id )})}><VisibilityRounded /></IconButton>
                </TableCell>
                <TableCell sx={{
                  backgroundColor,
                  borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>

                  <IconButton  onClick={() => setState({ changeRole: true , 
                    id: member.id, 
                    groupName: groupActuel.name, 
                    groupId: member.id, 
                    name: member.email })}><EditIcon /></IconButton>

                </TableCell>   
                <TableCell sx={{backgroundColor }}>{ member.email }</TableCell>
                <TableCell sx={{backgroundColor }}>{ member.role }</TableCell>
                <TableCell sx={{backgroundColor }}>{ member.type }</TableCell>
              </TableRow>
            )
          })
          }
        </TableBody>
      </Table>
      <Box>
        <Button sx= {{p: 2 }  } variant='contained' color="primary"
          onClick={() => setState({ open: false, openDelete: false, addUser: true, group: groups.find((gr: any) => gr.id === groupActuel.id )})}>Lisää käyttäjä ryhmään</Button>
                 
      </Box>
    </Box>
  )
}
export default ListGroupMembers;
