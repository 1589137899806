/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import {firebase, googleAuthProvider } from '../firebase/firebase'

export const login =  (uid: any, idToken: any ,email: any , speakerName: any, userProfile: any) => ({
  type: 'LOGIN',
  uid, idToken:idToken, email:email, speakerName: speakerName, userProfile:userProfile
});
export  const updateIdTokenOnly = (idToken: any) => ({
  type: 'UPDATE_IDTOKENONLY',
  idToken: idToken
}) 
export const updateIdToken = (idToken: any, userProfile: any ) =>

({
  type: 'UPDATE_IDTOKEN',
  idToken: idToken, userProfile: userProfile
})
export const startLogin = () => {
  return () => {
    return firebase.auth().signInWithPopup(googleAuthProvider)
      .then(function(result: any) {
      })
      .catch(function(error: any) {
      console.log('errorr in singin', error)
      });
  }
}

export const logout = () => ({
  type: 'LOGOUT'
});


export const startLogout = () =>  {
  logout();
  return () => {
    return  firebase.auth().signOut();
  }
}