import axios from 'axios';

const genericBaseUrl = `${process.env.REACT_APP_API_URL}/api/group-activity`;

const groupLastChangeTime = async (token: string) => {
  const config = {
    headers: { Authorization: token },
  };

  try {
    const response = await axios.get(genericBaseUrl, config); 
    return response.data;
  } catch (error: any) {
    console.error('Error:', error);

    // Tarkistetaan, onko error.response olemassa
    if (error.response) {
      return error.response.data?.message || 'Unknown error occurred';
    }

    return 'Network error or server is unreachable';
  }
};

const activitiesService = { groupLastChangeTime };

export default activitiesService;
