
import { error } from 'console';
import { editFilterData } from '../../actions/filters';
import database from '../../firebase/firebase';
import { getSubsheetIds } from '../genUtilities/getSubsheetIds';

const readSheetDataFromFire = async (dispatch: any, filters: any, services: any, auth: any, paramorgroups: any) => {

  const readFirebaseDatabase = async (fbParam: string) => {
    try{
      let snapshot: any = [];
      const data = await database.ref(`users/${auth.uid}/serviceYear/${filters.serviceYear}/${fbParam}`).once('value');
      await data.forEach((childSnapshot: any) => {
        snapshot.push({
          typeId: childSnapshot.key,
          ...childSnapshot.val()
        });
      });
      const dataArr = [...snapshot];
      let lineArray: any = [];
      for (let i = 0; i < 999; i++) {
        if(dataArr[0][`Line${i+100}`]){
          lineArray.push(dataArr[0][`Line${i+100}`]);
        } else {
          i=999
        }             
      }
      const newArray = lineArray.map((line: any) => {
        return line.split(',')
      });
      return newArray;  
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    };
  };

  const getSubjects = async () => {
    const allSubjects = services.reduce ((results: any, data: any) => { 
      const result = results;
      result[data.subject] = result[data.subject] || []
      result[data.subject].push ({
        sspeaker : data.sspeaker    
      });
      return result;
    },{});
    return allSubjects;
  }

  const getAllSubSheetsP = async (year: any, paramTable: any ) => {  
    let arrayTable: any = [];
    for await (const param of paramTable) {
      const newArray: any[]  = await readFirebaseDatabase(param);
      arrayTable.push(newArray);
      }
    return arrayTable;
    }
  const fetchData = async () => {
    try {
      const paramTable: string[] = await getSubsheetIds(filters);
      const subjects = await getSubjects();
      const sortedSubjects = Object.keys(subjects).sort();
      const parameters: Record<string, any> = await getAllSubSheetsP(filters.serviceYear, paramTable);
      
      const extractData = (category: string, searchKey: string) => {
        const categoryData = parameters[paramTable.findIndex(fi => fi === category)];
        const startIndex = categoryData?.findIndex((row: any) => row[0].includes(searchKey));
        
        if (startIndex === -1 || startIndex === undefined) {
          console.log(`${searchKey} not found in ${category}`);
          dispatch(editFilterData({ error: true, errorMessage: `${searchKey} puuttuvat` }));
          return [];
        }
        
        const slicedData = categoryData.slice(startIndex);
        const endIndex = slicedData.findIndex((row: any) => row[0].includes('/**'));
        return slicedData.slice(1, endIndex);
      };
  
      const extractData1  = (category: string, searchKey: string) => {
        const categoryData = parameters[paramTable.findIndex(fi => fi === category)];
        const startIndex = categoryData?.findIndex((row: any) => row[0].includes(searchKey));
        
        if (startIndex === -1 || startIndex === undefined) {
          console.log(`${searchKey} not found in ${category}`);
          dispatch(editFilterData({ error: true, errorMessage: `${searchKey} puuttuvat` }));
          return [];
        }
        
        const slicedData = categoryData.slice(startIndex);
        const endIndex = slicedData.findIndex((row: any) => row[0].includes('/**'));
        return slicedData.slice(1, endIndex);
      };


      const saestajat = extractData('Säestäjät ja esilaulajat', 'Säestäjät');
      const esilaulajat = extractData('Säestäjät ja esilaulajat', 'Esilaulaja');
  
      dispatch(editFilterData({
        parameters: {
          typesValues: parameters[paramTable.findIndex(fi => fi === 'Tyypit')],
          TMKValues: parameters[paramTable.findIndex(fi => fi === 'TMK-nimet')],
          locationValues: parameters[paramTable.findIndex(fi => fi === 'Paikkanimet')],
          seuraIsannat: parameters[paramTable.findIndex(fi => fi === 'Seuraisännät')],
          saestajat_ja_esilaulajat: parameters[paramTable.findIndex(fi => fi === 'Säestäjät ja esilaulajat')],
          esilaulajat,
          saestajat,
          valvojat: parameters[paramTable.findIndex(fi => fi === 'Valvojat')],
          aanitarkkailijat: parameters[paramTable.findIndex(fi => fi === 'Äänentoisto')],
          tulkit: parameters[paramTable.findIndex(fi => fi === 'Tulkit')],
          Toimikunnat_ja_alaryhmät: parameters[paramTable.findIndex(fi => fi === 'Toimikunnat ja alaryhmät')],
          kassat: parameters[paramTable.findIndex(fi => fi === 'Kahvikassat')],
          subjectValues: sortedSubjects,
        },
      }));
  
      return parameters;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
    
   return fetchData();      
}
export default readSheetDataFromFire;