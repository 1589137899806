import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconButton, CircularProgress, RadioGroup, Radio, Table, TableBody, TableHead, TableRow, TableCell, TextField, Typography, FormControl, FormControlLabel, 
    Box, Button, Tooltip } from '@mui/material';
import { editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import SidePanel from '../sidepanel/SidePanel';
import SidePanelMedium from '../sidepanel/SidePanelMedium';
import { colors } from '../../styles/themeStylesSx';
import { GroupRounded } from '@mui/icons-material';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import { DeleteRounded } from '@mui/icons-material';
import submitUsersToState from '../drives/submitUsersToState';
import {subHeaderStyles} from '../../styles/themeStylesSx';
import moment from 'moment';
import AddUserComponent from './AddUserComponent';
import QueryGroupsSimple from './QueryGroupsSimple';
import QueryDrivesSimple from './QueryDrivesSimple';
import MouseOverPopover from '../../utilities/MouseOverPopover';
import DeleteUser from './DeleteUser';

import {sortByDrive, sortByName,  sortByCreated, sortByLastLogin, } from '../../actions/filters';
let popOverText = '';

export default function  UserListing()  {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  let users: any = useSelector((state: RootState) => state.users);
  const auth: any = useSelector((state: RootState) => state.auth);
  const token: any = `bearer ${auth.idToken}`;
  const [loading, toggleLoading] = useState(false);
  const [state, setState] = useState<any>({
    kind: '',
    etag: '',
    id: 0,
    email:  '',
    role: '',
    type: 'any',
    status: '',
    käyttäjä: '',
    openAdd: false,
    userId: 0,
    groupId: '',
    editLine: false,
    deleteUSer: false,
    openDelete: false,
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    popOverText =`Näyttää tilin drive-oikeudet tai ryhmät`;
  };
  const handlePopoverOpen1 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    popOverText = 'Rajaa haun tulokset tekstin mukaan. Listan lopussa LISÄÄ TILI - painonappi'
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
 
  
  
  let workUsers: any = users;

  useEffect(() => {
    if (!auth.idToken) {
      navigate('/');
      return;
    }
    const readGroups = async () => {
      if(auth.idToken)  {
        submitUsersToState(token, dispatch);
        //await submitGroupsToState(token, auth.uid, dispatch, 'no', filters, auth );
        //await readMembers(groups);        
        toggleLoading(false)
      };
    }; 
    toggleLoading(true);
    readGroups();  
    dispatch(editFilterData({searchText:''}));
  },[token]);
  if (!filters.admin) return <div> EI  oikeuksia</div>
  if (loading) return <div> <CircularProgress /> loading all the way</div>
  if (!auth.idToken) return <div> Kirjaudu järjestelmään</div>
 
  const parseDate = (date: string) => 
    date === '' ? moment('1970-01-01', 'YYYY-MM-DD') : moment(date, 'D.M.YYYY');
  
  const sortUsers = (a: any, b: any, key: 'lastLoginDate' | 'creationDate') => {
    const dateA = parseDate(a[key]);
    const dateB = parseDate(b[key]);
    return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
  };
  
  if (filters.sortBy === 'lastLogin') {
    workUsers.sort((a:any, b:any) => sortUsers(a, b, 'lastLoginDate'));
  } else if (filters.sortBy === 'created') {
    workUsers.sort((a:any, b:any) => sortUsers(a, b, 'creationDate'));
  } else {
    workUsers.sort((a:any, b:any) => a.primaryEmail.localeCompare(b.primaryEmail));
  }
  
  //const searchText = filters.text.split(' ');
  const  workArray = workUsers.filter((perm: any) => perm.primaryEmail.toLowerCase().indexOf(filters.searchText.toLowerCase()) !== -1);
  workUsers = workArray;

  const handleChange = (e: any) => {
    if (e.target.value === 'name' ) {
      dispatch(sortByName());

    } else if (e.target.value === 'drive' ) {
      dispatch(sortByDrive());
    } else if (e.target.value === 'created' ) {
      dispatch(sortByCreated());
    } else if (e.target.value === 'lastLogin' ) {
      dispatch(sortByLastLogin())
    } else dispatch(sortByName());
  }; 

  const paluu = async () => { 
    navigate("/info");

  }
  const submit = async () => {
    setState({ openDrives: false, openGroups: false, addUser: true, deleteUser: false });
  };          

  const handleSearchChange = (e: any) =>  {
    const searchText = e.target.value;
    dispatch(editFilterData({searchText: searchText}))
   
  }

  return (
    <Box>
      <SidePanelMedium
        open={ state.openGroups === true }
        close={ () => setState({ openGroups: false })}
        >
        <Box>
          {state.openGroups &&
            <QueryGroupsSimple primaryEmail = {state.primaryEmail } close ={ () => setState({ openGroups: false })} />
          }
        </Box>
      </SidePanelMedium>

      <SidePanel
        open={ state.addUser === true }
        close={ () => setState({ addUser: false })}        
        >
          {state.addUser &&
            <AddUserComponent  close ={ () => setState({ openStrike: false, openSpeaker: false, editLine: false })} />    
          } 
            
      </SidePanel>
      <SidePanelMedium
      open={ state.openDrives === true }
      close={ () => setState({ openDrives: false })}
      >
      {state.openDrives &&
        <QueryDrivesSimple primaryEmail = {state.primaryEmail } users={users} close ={ () => setState({ openDrives: false })} />  
      }
      </SidePanelMedium>

      <SidePanel
      open={ state.deleteUser === true }
      close={ () => setState({ deleteUser: false })}
      >
      {state.deleteUser && 
      <DeleteUser primaryEmail = {state.primaryEmail} users = {users} openDelete = {true} close = { () => setState({deleteUser: false})} />
     }
      </SidePanel> 
        
      <Box>
        <FormControl>
          <Typography 
           aria-owns = { open ? 'mouse-over-popover1' : undefined}
           aria-haspopup = "true"
           onMouseEnter={handlePopoverOpen1}
           onMouseLeave={handlePopoverClose}
          sx={ subHeaderStyles }>{'Lajittelu' }</Typography>
          <RadioGroup aria-label="gender" name="gender1" value={ filters.sortBy} onChange={handleChange}>
            <FormControlLabel value="name" control={<Radio />} label="Nimi" />
            <FormControlLabel value="created" control={<Radio />} label="Luontipäivä" />
            <FormControlLabel value="lastLogin" control={<Radio />} label="Viim kirjautuminen" />
          </RadioGroup>
        </FormControl>
        <TextField
      
          style={{ minWidth: '150px'}}
          label="Rajaus"
          autoFocus
          name="rajaus"
          value={filters.searchText}
          onChange= {handleSearchChange}
        />
      </Box>    
      <Box>
     
      
        <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
          <TableHead>
            <Tooltip title="Missä drivekansiossa, Missä ryhmissä, Poista käyttäjä" arrow>
              <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                <TableCell></TableCell> 
                <TableCell>{'Email'}</TableCell>      
                <TableCell>{'Luotu'}</TableCell>
                <TableCell>{'Viimeinen käyttö'}</TableCell>
              </TableRow>
            </Tooltip>
          </TableHead>
          <TableBody>             
            { workUsers.map((user: any, i: number) => {
              const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (            
              <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${user.id}_row21`}>
                <TableCell
                  aria-owns = { open ? 'mouse-over-popover1' : undefined}
                  aria-haspopup = "true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  >
                  <IconButton  onClick={() => setState({ openDrives: true, openGroups: false, deleteUser: false, primaryEmail: user.primaryEmail  })}><FolderRoundedIcon /></IconButton>
                  <IconButton onClick={() => setState({ openDrives: false, openGroups: true, deleteUser: false, primaryEmail: user.primaryEmail })}><GroupRounded/></IconButton>
                  <IconButton onClick={() => setState({ openDrives: false, openGroups: false, deleteUser: true, primaryEmail: user.primaryEmail })}><DeleteRounded/></IconButton>
             
                </TableCell>  
                <TableCell sx={{backgroundColor }}>{ user.primaryEmail }</TableCell>
                <TableCell sx={{backgroundColor }}>{ user.creationDate }</TableCell>
                <TableCell sx={{backgroundColor}}>{ user.lastLoginDate }</TableCell>
              </TableRow>
            )
            })}        
          </TableBody>
        </Table>
        <Box sx={{ mt: 1 }}>         
          <Box>
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {submit}>
              {('Lisää tili')}
            </Button>   
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {paluu}>
              {('Paluu')}
            </Button>       
          </Box>          
        </Box>
    </Box>
  </Box>
  )
}
