import programService from "../../services/programService";
import { editFilterData } from "../../actions/filters";

export const GenGetAllSubSheetsToFire = async (dispatch: any, sheetId: any, serviceYear: any, token: any, auth: any ) => {    
  dispatch(editFilterData({loadingSubSheets: true})) 
  let initialLines: any = []
  try{
    const fileId = process.env.REACT_APP_SHEET_ID;
    const initialRows = await programService.getAllSubSheetsToFire(fileId, sheetId, token, serviceYear, auth.uid, auth.email );

    if (Array.isArray(initialRows)) {
      initialLines = initialRows;
      dispatch(editFilterData({loadingSubSheets: false}))
    }
    else {
      initialLines = [];
      dispatch(editFilterData({error: true, errorMessage: 'GenGetAllLines to firebase failed, check sheetId params ', loadinngSubSheets: false}));
    };
  }
  catch(error) {
    console.log('Error in getAllSubSheetsToFire', error);
    dispatch(editFilterData({error: true, errorMessage: 'GenGetAllLines to firebase failed, check sheetId params ', loadinngSubSheets: false}));
  return [];
  }
}
