import {
  initialDb,
  retrievePermissions,
  retrieveDrives,
  loadGroupsDb,
  retrieveGroupsDb,
  loadDrivesDb,
  loadPermissionsDb,
  deleteAllPermissions,
} from '../../services/sqlLiteServices';


export const loadDataSqlLite = async (idToken: any) => {

  let drives: any = []
  let permissions: any = [];
  let groups: any= [];
  const authToken = `${idToken}`;

  try {
    await initialDb(authToken);
  } catch (error) {
    console.error('Error loading initial database:', error);
  }
  try {
    await deleteAllPermissions(authToken);
  } catch (error) {
    console.error('Error deleting all permissions from sql drives:', error);
  }
  try {
    drives = await loadDrivesDb(authToken);
  } catch (error) {
    console.error('Error retrieving drives:', error);
  }
  try {
    drives = await retrieveDrives(authToken);
  } catch (error) {
    console.error('Error retrieving drives:', error);
  }

  try {
      await loadPermissionsDb(authToken);
    } catch (error) {
      console.error('Error retrieving permissions:', error);
    }
  try {
    permissions = await retrievePermissions(authToken);
  } catch (error) {
    console.error('Error retrieving permissions:', error);
  }

  try{
    await loadGroupsDb(authToken);
  } catch (error) {
    console.error('Error retrieving groups:', error);
  }
  try {
    groups = await retrieveGroupsDb(authToken);   
  } catch (error) {
    console.error('Error retrieving permissions:', error);
  }
  return ({
    groups,
    drives,
    permissions
  })

};