import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/ConfigureStore';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { editFilterData } from '../../actions/filters';
import { CircularProgress, Box,  Button, IconButton, Typography, Table, TableBody, TableHead, TableRow, TableCell ,  } from '@mui/material';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import { colors } from '../../styles/themeStylesSx';
import { loadDataSqlLite } from './loadDataSqlLite';

const LoadSqlTables: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth : any = useSelector((state: RootState) => state.auth);
  const idToken: any = auth.idToken;
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<any>({permissions: [], drives:[], groups: []})

  useEffect(() => {
    const loadData = async () => {
      if (!idToken) {
        navigate('/');
        return;
      }
      const authToken = `bearer ${idToken}`;
      const returned: any = await loadDataSqlLite(authToken);
    
      
      setState({groups: returned.groups, drives: returned.drives, permissions: returned.permissions});
      setLoading(false);
      dispatch(editFilterData({loading: false}));
    };
    setLoading(true)
    dispatch (editFilterData({loading: true}))
    loadData();
  }, [idToken, navigate, dispatch,]);
  if (loading) return <div><CircularProgress/> Latailee Sql-kantoja, venaa vielä, ei kestä kamalan kauan</div>

  return (
    <Box>
      <Box>
        <Typography sx={ subHeaderStyles }>{`Drives ${state.drives.length}`}</Typography>
      </Box>
      <Box>
      <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
      <TableHead>
        <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
          <TableCell>{'Id'}</TableCell>
          <TableCell>{'Nimi'}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {state.drives.map((line: any , i: any)=> {
          const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
          return (
            <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${line.id}${i} _row8`}> 
              <TableCell sx={{backgroundColor }}>{ line.id }</TableCell>
              <TableCell sx={{backgroundColor }}>{ line.name }</TableCell>
            </TableRow>
          )
        })
        }
      </TableBody>
    </Table>
    </Box>
    <Box>
      <Typography sx={ subHeaderStyles }>{`Permissions ${state.permissions.length}`}</Typography>
    </Box>
    <Box>
      <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            <TableCell>{'Id'}</TableCell>
            <TableCell>{'Drivenamei'}</TableCell>
            <TableCell>{'DrivenId'}</TableCell>
            <TableCell>{'Type'}</TableCell>
            <TableCell>{'Role'}</TableCell>
          </TableRow>
        </TableHead>
      <TableBody>
        {state.permissions.map((line: any , i: any)=> {
          const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
          return (
            <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${line.id}${i}_row8`}>  
              <TableCell sx={{backgroundColor }}>{ line.id }</TableCell>
              <TableCell sx={{backgroundColor }}>{ line.driveName }</TableCell>
              <TableCell sx={{backgroundColor }}>{ line.driveId }</TableCell>
              <TableCell sx={{backgroundColor }}>{ line.type }</TableCell>
              <TableCell sx={{backgroundColor }}>{ line.role }</TableCell>
            </TableRow>
          )
        })
        }
      </TableBody>
    </Table>
    </Box>
    <Box>
      <Typography sx={ subHeaderStyles }>{`Groups ${state.groups.length}`}</Typography>
    </Box>
    <Box>
      <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            <TableCell>{'Id'}</TableCell>
            <TableCell>{'Email'}</TableCell>
            <TableCell>{'Name'}</TableCell>
            <TableCell>{'owner'}</TableCell>
            <TableCell>{'UsersId'}</TableCell>
          </TableRow>
        </TableHead>
      <TableBody>
        {state.groups.map((line: any , i: any)=> {
          const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
          return (
            <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${line.id}${i}_row8`}>  
              <TableCell sx={{backgroundColor }}>{ line.id }</TableCell>
              <TableCell sx={{backgroundColor }}>{ line.email }</TableCell>
              <TableCell sx={{backgroundColor }}>{ line.name }</TableCell>
              <TableCell sx={{backgroundColor }}>{ line.owner }</TableCell>
              <TableCell sx={{backgroundColor }}>{ line.usersId }</TableCell>
            </TableRow>
          )
        })
        }
      </TableBody>
    </Table>
    </Box>
   </Box>
  )
};

export default LoadSqlTables;