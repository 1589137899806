/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import { v4 as uuidv4 } from 'uuid';
import database from '../firebase/firebase';

export const editFilterData = (filterData) => ({
  type: 'EDIT_FILTER_DATA',
  filterData
});
export const setTextFilter = (text = '') => ({
  type: 'SET_TEXT_FILTER',
  text
});
export const setDaysToState = (daysToState) => ({
  type: 'SET_DAYS_TO_STATE',
  daysToState
});
export const setTypesToState = (typesToState) => ({
  type: 'SET_TYPES_TO_STATE',
  typesToState
});
export const setStartDateRed = (startDate) => ({
  type: 'SET_START_DATE_RED',
  startDate
});
export const setEndDateRed = (endDate) => ({
  type: 'SET_END_DATE_RED',
  endDate
});

export const sortByCreated = () => ({
  type: 'SORT_BY_CREATED'
});
export const sortByDrive = () => ({
  type: 'SORT_BY_DRIVE'
});
export const sortByLastLogin = () => ({
  type: 'SORT_BY_LAST_LOGIN'
});
export const sortByName = () => ({
  type: 'SORT_BY_NAME'
});
export const sortByRole = () => ({
  type: 'SORT_BY_ROLE'
});

export const setStartDate = (startDate) => ({
  type: 'SET_START_DATE',
  startDate
});

export const setListAll = (listAll) => ({
  type: 'SET_LIST_ALL',
  listAll
});
export const haveToReadDrive = (haveToReadDrive) => ({
  type: 'HAVE_TO_READ_DRIVE',
  haveToReadDrive
});
export const setEndDate = (endDate) => ({
  type: 'SET_END_DATE',
  endDate
});

export const setServiceYearListSpeakers = (serviceYearSpeakers) => ({
  type: 'SET_SERVICE_YEAR_SPEAKERS',
  serviceYearSpeakers
});
export const setSheetData = (sheetData) => ({
  type: 'SET_SHEET_DATA',
  sheetData
});
export const setSheetYears = (sheetYears) => ({
  type: 'SET_SHEET_YEARS',
  sheetYears
});
export const setCalendarIds = (calendarDataArray) => ({
  type: 'SET_CALENDAR_IDS',
  calendarDataArray
});

export const speakerEdited = (truefalse) => ({
  type: 'SPEAKER_EDITED',
  truefalse
});
export const serviceEdited = (truefalse) => ({
  type: 'SERVICE_EDITED',
  truefalse
});

export const setLoading = (loading) => ({
  type: 'SET_LOADING',
  loading
});

export const updateServiceData = (serviceProgramName, lastChangeTimestamp,  servYear) => {

  return (dispatch) => {
    dispatch(editFilterData( { serviceProgramName: serviceProgramName } ));
  }

}
