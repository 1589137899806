/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

export const addUsers= (usersData: any) => ({
  type: 'ADD_USERS',
  usersData
});

export const addAllUsers= (allUsers: any) => ({
  type: 'ADD_ALL_USERS',
  allUsers
});

export const emptyUsers= () => ({
  type: 'EMPTY_USERS',

});