import React, {useEffect} from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {RootState} from '../../store/ConfigureStore';

export default function  UserComponent()  {
   const authorit: any = useSelector((state: RootState) => state.auth);
   const navigate = useNavigate();
   useEffect(() => {
    if (!authorit.idToken) {
      navigate('/');
      return;
    }
   }, [authorit.idToken, navigate]);
  
  const filters: any = useSelector((state: RootState)=> state.filters);
  if (!filters.admin) return <div> Ei oikeuksia </div>

  return(
    <div>
Voit listata käyttäjän, lisätä käyttäjän sekä tutkia, 
missä ryhmissä käyttäjä on tai mihin drive-kansioihin hänellä on oikeuksia.
Voit myös lisätä käyttäjän ryhmiin.
    </div>
   )
}