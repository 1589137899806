/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch  } from 'react-redux';
import deleteGroupMemberLine from './deleteGroupMemberLine'
import submitListMembersOfGroup from '../submitListMembersOfGroup'
import { Box, Button, Typography, CircularProgress, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { setLoading, haveToReadDrive, editFilterData } from '../../../actions/filters';
import {addMembersToGroup} from '../../../actions/groups';
import {RootState} from '../../../store/ConfigureStore';
import { colors } from '../../../styles/themeStylesSx';

import { subHeaderStyles, headerStyles } from '../../../styles/themeStylesSx';
type DeleteGroupType = {
  member?: any,
  group?: any,
  open?: boolean,
  openDelete: any,
  members?: any,
  close: () => void,
};
const DeleteGroupMember =  (props: DeleteGroupType) => {
  const dispatch = useDispatch();
  const close = props.close;
  const groups: any = useSelector((state: RootState) => state.groups)
  const authh: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const groupActuel = props.group;
  const [ loading, toggleLoading ] = useState(false);
  const [otherGroups, setOtherGroups] = useState([])
  const [confirmed, setConfirmed] = useState(false);
  const token: any = `bearer ${authh.idToken}`;
   let otherGroup: any = []
  useEffect( () => {
    setOtherGroups([]);
    if (groups.length === 0)
      close();
  }, [props.member]);

  useEffect( () => {
    if (confirmed)  {
      setConfirmed(false);
      
      close()
    };
  }, [filters.somethchanged]);

  const membersFunc = async (token: any,id: any, name: any) =>  {
  const membersArray =  await submitListMembersOfGroup(token, id);
    const workArray =  membersArray.find((memberid: any) => {
      return props.member.email === memberid.email;
    });
    if (workArray){
      otherGroup.push(name)
      setOtherGroups(otherGroup)
    }
  }
  const readMembers = async (id: any,name: any) => {
    await membersFunc(token, id,name);
    toggleLoading(false);
    dispatch(setLoading(false));
  }

  const memberOfOtherGroups =  async () => {
    dispatch(setLoading(true));  
    toggleLoading(true);
    for (const group of groups) {
      await readMembers(group.id,group.name);   
    }
  }

  const deleteGroupMember = async () => {

    const deleteGroupMemberCall = async () => {
      const email = props.member.email;
      const role = props.member.role
      // eslint-disable-next-line no-undef
      if (window.confirm('Oletko varma: Poistat ' + email + ' roolilla ' + role )) {
        setConfirmed(true);
        const ind = filters.userEmails.findIndex((use: any) => {
          return use.name === groupActuel.name
        })
        if (ind !== -1) {
          dispatch(editFilterData(filters.userEmails.splice(ind,1)));
        }
        await  deleteGroupMemberLine(token, groupActuel.id,props.member.id);
        const members = props.members.filter((memb: any) => memb.id !== props.member.id);
        dispatch(addMembersToGroup(groupActuel.id, members));
        dispatch(editFilterData({somethchanged : Math.random()}));
        toggleLoading(false)
      }
      else {
        toggleLoading(false);

      }
    }

    toggleLoading(true);
    await deleteGroupMemberCall();
    dispatch(haveToReadDrive(true))
    if (loading) return (<div><CircularProgress/>..deleting groupmember</div>)

    //close()
  }

  const goBack = () => {
   close()
  }
  if (loading) return (<div><CircularProgress/> ..circucircu </div>);
  return (
    <Box>
      {props.openDelete ?
        <Box>
          { (groupActuel) &&
            <Box>
              <Typography sx={ subHeaderStyles }>{`Poista ${props.member.email} ryhmästä ${groupActuel.name} `}</Typography>
              <Button variant="contained" color="primary"
                onClick = {(e) => {goBack()}}>Paluu</Button>
                <Button variant="contained" onClick={() => deleteGroupMember()} >poista </Button>
            </Box>
          }
        </Box>
        :
        <Box>
          { (groupActuel) &&
          <Box>
            <Typography sx={ headerStyles }>{`Ryhmässä ${groupActuel.name} `}</Typography>
            <Typography sx={ subHeaderStyles }>{`Missä muissa ryhmissä ${props.member.email} on `}</Typography>
            <Button variant="contained" onClick={() => memberOfOtherGroups()} > Tarkista </Button>
            <Table>
              <TableBody>
              {
                otherGroups.map((gr: any, i: any) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                  return (
                    <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {i}>
                      <TableCell>{gr}</TableCell>
                    </TableRow>
                  )
                })
              }
              </TableBody>
            </Table>
          </Box>
          }
        </Box>}
    </Box>
  )
}

export default DeleteGroupMember
