/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import { v4 as uuidv4 } from 'uuid';
import database from '../firebase/firebase';


// export const editDrive1 = (driveId,permissionsData) => ({
//   type: 'EDIT_DRIVE1',
//   driveId,
//   permissionsData
// });
export const editDrive = (permissionsData) => ({
  type: 'EDIT_DRIVE',
  permissionsData
});
export const removeLines = (permissionsData) => ({
  type: 'REMOVE_LINES',
  permissionsData
});
export const editPermission= (driveId, permission) => ({
  type: 'EDIT_PERMISSION',
  driveId,
  permission
});
export const editYouAreOrganizer= (driveId, youAreOrganizer) => ({
  type: 'EDIT_YOU_ARE_ORGANIZER',
  driveId,
  youAreOrganizer
});

export const addDrives= (drivesData) => ({
  type: 'ADD_DRIVES',
  drivesData
});
export const addDrivesOnce= (driveArray) => ({
  type: 'ADD_DRIVES_ONCE',
  driveArray
});


export const emptyDrives= () => ({
  type: 'EMPTY_DRIVES',

});

export const startAddDrives = (drivesData = {}) => {
  return (dispatch, getState ) => {
    const uid = getState().auth.uid;
    return database.ref(`users/${uid}/driveList`).push(drivesData)
      .then((ref)  => {
        const {
          drivesId = ref.key,
          kind = '' ,
          driveId ='',
          driveName = '',
          permissions = []	,

        } = drivesData
        dispatch(addDrives({
          drivesId : ref.key,
          ...drivesData
        }))
      })
  }
};

export const startAddDrivesOnce = (drivelist ) => {
  return (dispatch, getState ) => {
    const uid = getState().auth.uid;
    drivelist.forEach(drivesData => {
      return database.ref(`users/${uid}/driveList`).push(drivesData)
        .then((ref)  => {
        const {
          drivesId = ref.key,
          kind = '' ,
          driveId ='',
          driveName = '',
          permissions = []	,

        } = drivesData
        dispatch(addDrives({
          drivesId : ref.key,
          ...drivesData
        }))
      })   
    })
  }
};

export const  startRemoveDatabase = () => {
  return (dispatch,getState ) => {
    const uid = getState().auth.uid;
    database.ref(`users/${uid}/driveList`).remove()
      .then(() => {
        if (getState().drives.length > 0) {
          const empty = []
          dispatch(emptyDrives( {services:empty}))
        }
      }).catch((e) => {
        console.log('Did not remove data drives.js,',e)
      })
  }
}