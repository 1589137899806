/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import groupServices from '../../../services/groupServices';
import {addGroups } from  '../../../actions/groups';
import database from '../../../firebase/firebase';
import { editFilterData } from '../../../actions/filters';
import activitiesService from '../../../services/activitiesService'; 
import {retrieveGroupsDb, initialDb } from '../../../services/sqlLiteServices';
import moment from 'moment-timezone';
import { loadDataSqlLite } from '../../profile/loadDataSqlLite';

const submitGroupsToState = async (token: any , uid: any, dispatch: any, refresh: string, filters: any, auth: any ) => {

  const readGr = async () => {
    try {
      loadDataSqlLite(token);
      //await initialDb(token);
    }catch(error){
      console.error('error in retrieving data from  MemoryDb', error)
      dispatch(editFilterData({error: true, errorMessage: 'Memorydb kannan virhe submitGroupsToState'}))
    }
    // try{
    //  const response = await groupServices
    // .groupList(token, uid )
    // if (response === 'Timeout reached') {
    //   dispatch(editFilterData({error: true, errorMessage: 'Palvelin teki aikakatkaisun. Ole hyvä ja tee kysely uudestaan. Kyllä palvelin josssain kohtaa taas jaksaa loppuun asti', stopOutput: true }))
    //   return
    // }
    // }catch(error) {
    //   console.error('Error fetching grouplist', error)
    //   dispatch(editFilterData({error: true, errorMessage: 'virhe ryhmien haussa'})) ;
    // }
  }

  const groupFireModifiedTimeComp = async () => {
    let groupFireModifiedTime: any = '';
   
    try {
        await database.ref(`users/hryproject1234567890/groupLastChangeTimestamp`)
        .once('value' ,  (snapshot: any) => {
          if (snapshot.exists()){
            groupFireModifiedTime = snapshot.val();
          }
          else {
            dispatch(editFilterData({error: true, errorMessage: ' Haetaan parametridataa Googlen pilvestä...kestää normaaliakin pidempään...'}))
            
          }
        });
        //console.log('groupFireModifiedTimettzzzz', groupFireModifiedTime);
      return groupFireModifiedTime;
    } catch (e){
      console.error('errori in app checkchanges of service',e);
      dispatch(editFilterData({error: true, errorMessage: 'Firebasen ryhmien muutosten tarkistus epäonnistui'}));
    };
  }
  const readFirebaseDatabase = async (fbParam: string) => {
    try {
      const response: any = await retrieveGroupsDb(token);

      dispatch(addGroups(response));
    }catch(error: any){
      console.error('error in getRowdfrom MemoryDb', error.response.status);
      if (error?.response.status === 409) {
        dispatch(editFilterData({error: true, errorMessage: 'sqlLiteServices down. HAetaan  ryhmätiedot googlen pilvipalvelusta. Hae kahvit, voi kestää..'}))
        await readGr();
        await readFirebaseDatabase('group')
      }
      else  dispatch(editFilterData({error: true, errorMessage: 'Memorydb kanna luku virhe submitGroupsToState'}))
    }
    // try{
    //   let snapshot: any = [];
    //   const data = await database.ref(`users/hryproject1234567890/${fbParam}`).once('value');
    //   if (data.exists()) {  
    //     await data.forEach((childSnapshot: any) => {
    //       snapshot.push({
    //         typeId: childSnapshot.key,
    //         ...childSnapshot.val()
    //       });
    //     });
    //     //dispatch(addGroups(snapshot));
    //   }  
    // }
    // catch (error) {
    //   console.error('Error fetching data:', error);
    // };
  } 
 
  if (refresh !== 'no') {
    await readGr()
    await readFirebaseDatabase('groups');
  }
  else {
    const modifiedTimeOfFirebase = await groupFireModifiedTimeComp();
    const workFire = moment(modifiedTimeOfFirebase.lastChangeTimestamp,'YYYY-MM-DD HH:mm:ss.SSS').format('YYYY-MM-DD HH:mm:ss.SSS');
    const modifiedTimeOfGoogleGroup = await activitiesService.groupLastChangeTime(token);
    const workGroups = moment.tz(modifiedTimeOfGoogleGroup.id.time,'YYYY-MM-DD HH:mm:ss.SSS','UTC').tz('Europe/Helsinki').format('YYYY-MM-DD HH:mm:ss.SSS');
    if (!modifiedTimeOfFirebase || 
      moment( workFire).isBefore(moment(workGroups))) {
      dispatch(editFilterData({error: true, errorMessage: ' Haetaan parametridataa Googlen pilvestä...kestää normaaliakin pidempään...'}))
      await readGr();
      await readFirebaseDatabase('groups'); 
    }
   
    else {
      await readFirebaseDatabase('groups'); 
    }
  }

}
export default submitGroupsToState