/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import axios from 'axios';

const noRoleUrl = `${process.env.REACT_APP_API_URL}/api/listAndAnalyzeUsers`;
const noRoleDriveUrl = `${process.env.REACT_APP_API_URL}/api/listAndAnalyzeDriveUsers`;
const directoryListUrl = `${process.env.REACT_APP_API_URL}/api/directoryList`;
const addAccountUrl = `${process.env.REACT_APP_API_URL}/api/insertUser`;
const groupQueryUrl = `${process.env.REACT_APP_API_URL}/api/inWhichGroups`;
const deleteUSerLine = `${process.env.REACT_APP_API_URL}/api/deleteUserLine`;

const noRoleList = async (token) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const response =  await axios.get( noRoleUrl, config);
    return response.data;
  }
  catch(error) {
    console.log('error when using noRoleUrl ', error);
    throw error;
  };
}
const noRoleDrive = async (token, accounts ) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const response =  await axios.post( noRoleDriveUrl,{accounts:accounts}, config);
    return response.data;
  }
  catch(error) {
    console.log('error when using noRoleUrl ', error);
    throw error;
  };
}

const userList = async (token) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const response = await axios.get( directoryListUrl, config);
    return response.data
  }
  
  catch(error) {
    console.log ( 'Error in userList', directoryListUrl, error);
    if (error.response) {
      if (error.response.status === 408) {
        return 'Timeout reached'
      }
      console.error('HTTP Status Code userServices: userList: ', error.response.status); // 408 in this case
      console.error('Response Data:', error.response.data); // The response payload, if available
      console.error('Response Headers:', error.response.headers); // The headers from the response
    } else 
    
      if (error.request) {
        console.error('No response received:  userServices: userList:', error.request);
      } else {
        console.error('Error creating the request:  userServices: userList:', error.message);
      }
    console.log('error reading users  userServices: userList:',error) 
    throw error;
  }
}

const deleteUserLine = async (token, primaryEmail) => {
  const config = {
    headers: { Authorization: token },
  }
  try{
     const response = await axios.delete( `${ deleteUSerLine }/${ primaryEmail }`, config);
     return ('ok');
  }catch(error)  {
    const response = error.response.status;
    console.log('Error in deleteUserLine Errorrcode = ', error.response.status);
    return response; 
  }
};

const inWhichGroups = async (token, primaryEmail) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const response =  await axios.post( groupQueryUrl,{query:`memberKey=${primaryEmail}` }, config);
    return response;
  }catch(error) {
    console.log('Error In inwhichgroupresponseis', error);
    throw error;
  };  
};

const insertAccount = async (token, accountData ) => {
  const config = {
    headers: { Authorization: token },
  }
  try{
     const response = await axios.post( addAccountUrl, 
    {creationDate: accountData.creationDate, 
      lastLoginDate: accountData.lastLoginDate, 
      primaryEmail:accountData.primaryEmail, 
      familyName: accountData.familyName,
      givenName: accountData.givenName,
      customerId: accountData.customerId,
      passWord: accountData.passWord,
      changePasswordAtNextLogin: true
    
    },
     config);
     return response
  }catch(error) {
    console.error(`Error in insertAccount ${error.response.status}`)
    const response = ({Error:  `Error ${error.response.status}`});
    return response;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userList : userList,
  insertAccount: insertAccount,
  inWhichGroups: inWhichGroups,
  deleteUserLine : deleteUserLine,
  noRoleList: noRoleList,
  noRoleDrive : noRoleDrive,
}