//Read CalIds from sheeets
//Read calendars by sheetId
import  React, {useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/ConfigureStore';
import { useNavigate } from 'react-router-dom';

export default function CalendarStart(){
 const authorit: any = useSelector((state: RootState) => state.auth);
 const navigate = useNavigate();
 useEffect(() => {
  if (!authorit.idToken) {
    navigate('/');
    return;
  }
 }, [authorit.idToken, navigate]);
 if (!authorit.idToken) return <div> Kirjaudu järjestelmään</div>
  return (
    <div>
 Listataan kalenteria
    </div>
  )
}
